const actions = {
 
  SENDMESSAGE_BEGIN: 'SENDMESSAGE_BEGIN',
  SENDMESSAGE_SUCCESS: 'SENDMESSAGE_SUCCESS',
  SENDMESSAGE_ERR: 'SENDMESSAGE_ERR',
  
  MESSAGES_BEGIN: 'MESSAGES_BEGIN',
  MESSAGES_SUCCESS: 'MESSAGES_SUCCESS',
  MESSAGES_ERR: 'MESSAGES_ERR',

  MEMBERS_BEGIN: 'MEMBERS_BEGIN',
  MEMBERS_SUCCESS: 'MEMBERS_SUCCESS',
  MEMBERS_ERR: 'MEMBERS_ERR',

  Socket_BEGIN: 'Socket_BEGIN',
  Socket_SUCCESS: 'Socket_SUCCESS',
  Socket_ERR: 'Socket_ERR',

  


  sendMessageBegin: () => {
    return {
      type: actions.SENDMESSAGE_BEGIN,
    };
  },

  sendMessageSuccess: data => {
    return {
      type: actions.SENDMESSAGE_SUCCESS,
      data,
    };
  },

  sendMessageErr: err => {
    return {
      type: actions.SENDMESSAGE_ERR,
      err,
    };
  },

  messagesBegin: () => {
    return {
      type: actions.MESSAGES_BEGIN,
    };
  },

  messagesSuccess: data => {
    return {
      type: actions.MESSAGES_SUCCESS,
      data,
    };
  },

  messagesErr: err => {
    return {
      type: actions.MESSAGES_ERR,
      err,
    };
  },

  membersBegin: () => {
    return {
      type: actions.MEMBERS_BEGIN,
    };
  },

  membersSuccess: data => {
    return {
      type: actions.MEMBERS_SUCCESS,
      data,
    };
  },

  membersErr: err => {
    return {
      type: actions.MEMBERS_ERR,
      err,
    };
  },

  socketBegin: () => {
    return {
      type: actions.Socket_BEGIN,
    };
  },

  socketSuccess: data => {
    return {
      type: actions.Socket_SUCCESS,
      data,
    };
  },

  socketErr: err => {
    return {
      type: actions.Socket_ERR,
      err,
    };
  },

};

export default actions;
