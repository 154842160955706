import Styled from 'styled-components';
import { Layout } from 'antd';
import { theme } from '../config/theme/themeVariables';

const Div = Styled(Layout)`
    background:transparent !important;
    .header{
        position: fixed;
        background:${theme['header-color']};
        padding: 0px 30px;
        z-index: 99;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .footer{
        padding: 30px;
        background:transparent !important;
        justify-content: center;
        display: flex;
    }
    .logo {
        width: 64px;
        img{
            width:100%;
        }
    }
    .header-right{
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 200px;
        width: 100%;
        button{
            &:active{
                background:transparent !important;
            }
            &:focus{
                background:transparent !important;
            }
        }
    }
    .site-layout{
        min-height:76vh;
        .menu-drawer{
            .ant-menu-item.ant-menu-item-only-child{
                padding:0px;
            }
        }
    }
    .user-profile{
        border:3px solid #EC6A40;
        background: #fff;
        font-size: 18px;
        font-weight: 600;
        color:#EC6A40;
        span.ant-avatar-string{
            line-height: 35px !important;
            letter-spacing: -2px !important;
        }
    }
`;

const SearchBox = Styled.div`
    max-width:500px;
    width:100%;
    display: flex;
    input{
        background: transparent;
        border: 0;
        border-bottom: 1px solid #70707033 !important;
        &:focus{
            outline: 0;
            border: 0;
            box-shadow: none;
        }
        &:active{
            outline: 0;
            box-shadow: none;
            border: 0;
        }
    }
    .ant-input-group-addon{
        background: transparent;
        border: 0;
        border-bottom: 1px solid #70707033;
    }
`
const SearchBar = Styled.div`
    width:100%;
    position: fixed;
    top: 64px;
    background: #fff;
    padding: 10px;
    z-index: 100;
    .ant-input-group-wrapper{
        border:1px solid #70707033;
        border-radius: 20px;
        .ant-input-wrapper{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
        }
        input{
            border:0;
            background: transparent;
            &:focus{
                outline: 0;
                border: 0;
                box-shadow: none;
            }
        }
        .ant-input-group-addon{
            border:0;
            background: transparent;
            padding: 0;
            width: 20px;
            height: 20px;
            justify-content: center;
            align-items: center;
            display: flex;
            margin-left: 10px;
        }
    }
`

const Container = Styled(Layout)`
    max-width: 1380px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    
    flex-direction: column;
    .footer-left{
        p{
            color:#707070;
            margin:0;
            font-size:12px;
        }
    }
    .footer-right{
        a{
            color:#707070;
            margin:0;
            font-size:12px;
            padding-left: 20px;
        }
    }
`

export { Div, Container, SearchBox, SearchBar };
