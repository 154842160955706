import actions from './actions';

const {
  FILTERSUCCESS,

} = actions;

const initialStateList = {
  data: {},
  loading: false,
  error: null,
};

const FilterReducer = (state = initialStateList, action) => {
  const { type, data, err } = action;
  switch (type) {
    case FILTERSUCCESS:
      return {
        ...initialStateList,
        data,
        loading: false,
      };
    
    default:
      return state;
  }
};

export default FilterReducer;
