const actions = {
 
  NOTIFICATION_BEGIN: 'NOTIFICATION_BEGIN',
  NOTIFICATION_SUCCESS: 'NOTIFICATION_SUCCESS',
  NOTIFICATION_ERR: 'NOTIFICATION_ERR',

  UNREADNOTIFICATION_BEGIN: 'UNREADNOTIFICATION_BEGIN',
  UNREADNOTIFICATION_SUCCESS: 'UNREADNOTIFICATION_SUCCESS',
  UNREADNOTIFICATION_ERR: 'UNREADNOTIFICATION_ERR',

  SENDNOTIFICATION_BEGIN: 'SENDNOTIFICATION_BEGIN',
  SENDNOTIFICATION_SUCCESS: 'SENDNOTIFICATION_SUCCESS',
  SENDNOTIFICATION_ERR: 'SENDNOTIFICATION_ERR',  

  notificationBegin: () => {
    return {
      type: actions.NOTIFICATION_BEGIN,
    };
  },

  notificationSuccess: data => {
    return {
      type: actions.NOTIFICATION_SUCCESS,
      data,
    };
  },

  notificationErr: err => {
    return {
      type: actions.NOTIFICATION_ERR,
      err,
    };
  },

  unreadNotificationBegin: () => {
    return {
      type: actions.UNREADNOTIFICATION_BEGIN,
    };
  },

  unreadNotificationSuccess: data => {
    return {
      type: actions.UNREADNOTIFICATION_SUCCESS,
      data,
    };
  },

  unreadNotificationErr: err => {
    return {
      type: actions.UNREADNOTIFICATION_ERR,
      err,
    };
  },

  sendNotificationBegin: () => {
    return {
      type: actions.SENDNOTIFICATION_BEGIN,
    };
  },

  sendNotificationSuccess: data => {
    return {
      type: actions.SENDNOTIFICATION_SUCCESS,
      data,
    };
  },

  sendNotificationErr: err => {
    return {
      type: actions.SENDNOTIFICATION_ERR,
      err,
    };
  },

};

export default actions;
