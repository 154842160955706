import React, { lazy, Suspense, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player'
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { addInWatched, getMovieData, getScheduleMovieById } from '../../redux/movies/actionCreator';
import { useHistory } from 'react-router-dom';
import { API } from "../../config/api";
// import socketIOClient from 'socket.io-client/dist/socket.io';
import { db, checkAuth, removeAuth } from '../../config/firebase';
import FeatherIcon from 'feather-icons-react';
// import { images } from '../../config/theme/images';
import config from '../../config/config';
import { message, Spin, Slider, Button } from 'antd';
import { PlayCircleFilled, PauseCircleFilled } from '@ant-design/icons';
const { theme, images, imagePath } = config;

let player;

let playStatus = false;
let DurationJs = 0;
let playingJs = false;
let chat = [];
let durationInterval;
let member = [];
let memberList = [];

// const socketIo = socketIOClient("https://app.cinemawalla.com", {
//     transports: ['websocket'],
//     reconnection: true,
//     reconnectionDelay: 1000,
//     reconnectionDelayMax : 5000,
//     reconnectionAttempts: Infinity
// });
// console.log('socketIo', socketIo);
const Player = ({ movie, userData, token, scheduleId, schedule, className, appPlayer=false, socketIo={} }) => {
  // console.log('movie, userData, token, scheduleId, className', movie, userData, token, scheduleId, className);
  const dispatch = useDispatch();
  const videoNode = useRef();
  const [loading, setLoading] = useState(true);
  const [playing, setPlaying] = useState(false);
  const [countryName, setcountryName] = useState('India');
  const [duration, setDuration] = useState(0);
  const [played, setPlayed] = useState(0);
  const [playedDuration, setPlayedDuration] = useState(0);
  const [watched, setWatched] = useState(true);
  const [hostStarted, setHostStarted] = useState(false);
  // const [schedule, setschedule] = useState(null);
  const [filmStart, setFilmStart] = useState(false);
  const [controls, setControls] = useState(false);
  const [host, setHost] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [socketId, setSocketId] = useState(null);
  
  useEffect(() => {
    // alert('playing === '+ playing);
  }, [playing]);

  useEffect(() => {
    console.log('socketIosocketIosocketIo', socketIo);
    if(socketIo && socketIo.id){
      const SocketId = socketIo.id;
      setSocketId(SocketId);    
      socketIo.on("group_joined", (data) => {
        // console.log('rooms',  data);
        if(data.user_id != userData.id){
          if(videoNode && videoNode.current){
            const videoPlayer = videoNode.current;
            const timeInVideo = videoPlayer.getCurrentTime();
            const message = {
              content: timeInVideo,
              type: 'lastDuration',
              room: scheduleId,
              userid: userData.id
            };
            sendMessage(message);
          }        
        }
      });
      
      socketIo.on("new_notice", (data) => {
        // console.log('!host && data.socket_id && videoNode', data.isHost, data.socket_id && Object.keys(videoNode).length>0);
        if(data.user_id != userData.id){
          if(data.socket_id && Object.keys(videoNode).length>0){
            console.log('data',  data);
            if(data.body.type == 'start'){
              setHostStarted(true);
            }else if(data.body.type == 'play'){
              const videoPlayer = videoNode.current;
              setPlaying(true);
              // setPlayed(Number(data.body.content));
              videoPlayer.seekTo(parseFloat(data.body.content));
            }else if(data.body.type == 'pause'){
              setPlaying(false);
            }else if(data.body.type == 'seek'){
              const videoPlayer = videoNode.current;
              // setPlayed(Number(data.body.content));
              // setPlaying(true);
              videoPlayer.seekTo(parseFloat(data.body.content));
            }else if(data.body.type == 'lastDuration'){
              console.log('videoNode.current', videoNode, videoNode.current);
              const videoPlayer = videoNode.current;
              console.log('videoPlayer', videoPlayer);
              if(data.body.content == 'back'){
                const timeInVideo = videoPlayer.getCurrentTime();
                addLastDuration(timeInVideo);
              }else{
                videoPlayer.seekTo(parseFloat(data.body.content));
              }
              setPlaying(true);
            }else if(data.body.type == 'syncRequest'){
              if(videoNode && host){
                // DurationJs = videoNode.getCurrentTime();
                // setDuration(videoNode.getCurrentTime());
                // console.log('DurationJs', DurationJs);
                const message = {
                  content: videoNode.getCurrentTime().toString(),
                  type:'syncScreeing',
                  room: scheduleId,
                  userid:userData.id
                };
                sendMessage(message);
              }
            }else if(data.body.type == 'syncScreeing'){
              const videoPlayer = videoNode.current;
              setPlaying(true);
              // setPlayed(Number(data.body.content));
              videoPlayer.seekTo(parseFloat(data.body.content));
            }
  
            // if(data.user_id != userData.id){
              // alert('type  '+ data.body.type);
              // console.log('videoNode', videoNode);
              // if(data.body.type == 'play'){
              //   // playerAction(data.body.type);
              //   playingJs = true;
              //   setPlaying(true);
              //   // DurationJs = Number(data.body.content);
              //   // setDuration(Number(data.body.content));
              //   // if(videoNode && DurationJs > 0){
              //   //   videoNode.seekTo(parseFloat(DurationJs), 'seconds');
              //   // }
              // }else if(data.body.type == 'pause'){
              //   playingJs = false;
              //   setPlaying(false);
              // }else if(data.body.type == 'lastDuration'){
              //   DurationJs = Number(data.body.content);
              //   setDuration(Number(data.body.content));
              //   addLastDuration(DurationJs);
              //   // alert('data.body.hosted = '+ data.body.hosted+'  '+data.body.content);
              //   // if(Number(data.body.content) > 0){
              //   //   setHostStarted(true);
              //   // }else if(host){
              //   //   setHostStarted(true);
              //   // }
              // }else if(data.body.type == 'seek'){
              //   playingJs = true;
              //   setPlaying(true);
              //   DurationJs = Number(data.body.content);
              //   setDuration(Number(data.body.content));
              //   if(videoNode && DurationJs > 0){
              //     // console.log('videoNode', videoNode, DurationJs);
              //     videoNode.seekTo(parseFloat(DurationJs), 'seconds');
              //   }
              // }else if(data.body.type == 'start'){
              //   setHostStarted(true);
              // }else if(data.body.type == 'syncRequest'){
              //   if(videoNode && host){
              //     DurationJs = videoNode.getCurrentTime();
              //     setDuration(videoNode.getCurrentTime());
              //     // console.log('DurationJs', DurationJs);
              //     const message = {
              //       content: DurationJs.toString(),
              //       type:'syncScreeing',
              //       room: scheduleId,
              //       userid:userData.id
              //     };
              //     sendMessage(message);
              //   }
              // }else if(data.body.type == 'syncScreeing'){
              //   playingJs = true;
              //   setPlaying(true);
              //   DurationJs = Number(data.body.content);
              //   setDuration(Number(data.body.content));
              //   if(videoNode && DurationJs > 0){
              //     // videoNode
              //     videoNode.seekTo(parseFloat(DurationJs), 'seconds');
              //   }
              // }
            // }else{
            //   if(data.body.type == 'mute'){
            //     setMuted(data.body.content);
            //   }else if(data.body.type == 'lastDuration'){
            //     addLastDuration(DurationJs);
            //     // alert('data.body.hosted = '+ data.body.hosted+'  '+data.body.content);
            //     // if(Number(data.body.content) > 0){
            //     //   setHostStarted(true);
            //     // }else if(host){
            //     //   setHostStarted(true);
            //     // }
            //   }
            // }
          }
        }
       
      });

      // document.addEventListener("message", function(event) {
      //   addLastDuration(DurationJs);
      // }, false);
    }
  }, [socketIo, socketIo.connected]);

  const [chats, setChats] = useState([]);
  const [videoUrl, setVideoUrl] = useState(null);
  const [posterUrl, setPosterUrl] = useState(null);
  const history = useHistory();

  const sendMessage = async(chat) => {
    let body = {};
    console.log('body', chat);
    // alert('chat = '+ chat.type);
    // console.log('socketIdsocketId', socketIo, socketIo.id);
    socketIo.emit("roomnotice", {
      room_id: scheduleId,
      msgtype: 'notice',
      body: chat,
      isHost: host,
      socket_id: socketIo.id,
      user_id: userData.id,
    });
  }

  useEffect(() => {
    if(movie && movie.movieUrl){
      setVideoUrl(movie.movieUrl);
      setPosterUrl(imagePath+movie.poster);
    }
  }, [movie]);

  useEffect(() => {
    if(host){
      setVideoUrl('');
      setPlaying(true);
      setControls(true);
      setVideoUrl(movie.movieUrl);
    }
  }, [host]);

  

  // useEffect(() => {
  //   const URL = videoUrl;
  //   setVideoUrl(null);
  //   if(controls){
  //     setVideoUrl(URL);
  //   }
  // }, [controls]);

  useEffect(() => {
    setTimeout(() => {
      if(userData && userData.id){
        getGeoInfo();
        if(scheduleId && schedule){
          // setLoading(false);
          setHost(schedule.scheduled_by == userData.id);
          // console.log(schedule.scheduled_by, userData.id);
          // if(appPlayer){
          //   setControls(true);
          //   if(new Date(returndata.data.scheduled_date).getTime() <= new Date(new Date().toUTCString()).getTime()){
          //       if(host){
  
          //       }else{
          //         if(movie && movie.movieUrl && movie.movieUrl.includes('vimeo.com')){
          //           message.info({ content: 'The playback controls for this screening is controlled by your host\'s player.', duration: 10 });
          //         }else{
          //           message.info({ content: 'The playback controls for this screening are with you. To manually synconise playback with your host, use the "Host Sync" button to get host\'s playback time.', duration: 10 });
          //         }
          //       }
  
          //   }
          // }else{
          //   setControls(returndata.data.scheduled_by == userData.id);
          // }
  
          // setVideoUrl(null);
          
          // setHostStarted(returndata.data.scheduled_by == userData.id);
          const user = schedule.scheduled_with.find(el => el.friend_id == userData.id);
          if(user){
            // console.log('socketId', socketIo.id);
            // socketIo.emit("roomconnect", {
            //   user_id: userData.id,
            //   room_id: scheduleId,
            //   socket_id: socketIo.id
            // });
          }else{
            history.push('/details/'+movie.id);
          }
        }else{
          setControls(true);
        }
      }
    }, 1000);
  }, [userData, scheduleId, schedule]);

  useEffect(() => {
    return () => {
      addLastDuration(playedDuration);
    };
  }, []);

  const onStart = () => {
    console.log('ON Start');
    if(host){
      // setHostStarted(true);
      const message = {
        content: played,
        hosted: true,
        type:'start',
        room: scheduleId,
        userid:userData.id
      };
      sendMessage(message);
    }
  }

  const getGeoInfo = () => {
    axios.get('https://ipapi.co/json/').then((response) => {
        let data = response.data;
        setcountryName(data.country_name);
    }).catch((error) => {
        console.log(error);
    });
  };


  const addInWatchedList = async (Duration) =>{
    // console.log('60% played', watched, Duration);
    if(watched){
      const data = {
        movieId: movie.id,
        watcherId: userData.id,
        inGroup: scheduleId?true:false,
        location: countryName,  
        duration: Duration.toString(),    
      };
      let returndata = await dispatch(addInWatched(data));
      if(returndata){
        if(returndata.type=="ADD_MOVIE_SUCCESS"){
          setWatched(false);
        }else if(returndata.type=="ADD_MOVIE_ERR"){
        }
      }else{
      }
    }
  }

  const onSeek = (seek) => {
    console.log('seek', seek);
    if(host){
      console.log('seek', seek);
      const message = {
        content: seek.toString(),
        type: 'seek',
        room: scheduleId,
        userid: userData.id
      };
      sendMessage(message);
    }
  }

  const addLastDuration = async (DurationValue)=>{
    // durationInterval = setInterval(() => {
      // alert('DurationValue = '+ DurationValue);
      if(DurationValue && DurationValue > 0){
        // const message = {
        //   content: DurationValue,
        //   type:'seek',
        //   room: scheduleId,
        //   userid:userData.id
        // };
        // Add APi to update watched time 
        const data = {
          movieId: movie.id,
          watcherId: userData.id,
          inGroup: scheduleId?true:false,
          duration: DurationValue,    
        };
        let returndata = await dispatch(addInWatched(data, token));
        if(returndata){
          if(returndata.type=="ADD_MOVIE_SUCCESS"){
            // setWatched(false);
          }else if(returndata.type=="ADD_MOVIE_ERR"){
          }
        }else{
        }
        // sendMessage(message);
      }
    // }, 10000);
    // console.log('played', played);
    
    
    // db.ref(`messages`).child(scheduleId).child('duration').set(played).then(data => {
    //   // //console.log('start');
    //   // $('#startButton, #startButton2').hide();
    //   // globalDuration = played;
    //   
    // },
    // err => {
    //     console.log(err);
    // });
  }

  const onChange = (value) => {
    // const videoPlayer = videoNode.current;
    console.log('onChange: ', value);
    setPlayed((value/duration));
    // videoPlayer.seekTo(parseFloat(value));
  };
  const onAfterChange = (value) => {
    const videoPlayer = videoNode.current;
    console.log('onAfterChange: ', value, played, duration);
    // setPlayed(value);
    videoPlayer.seekTo(parseFloat(value));
  };

  useEffect(() => {
    // console.log('state.played', played);
    // console.log((playedDuration*60)> 0 , (playedDuration*60) >= (duration)/100);
    if((playedDuration*60)> 0 && (playedDuration*60) >= (duration)/100){
      addInWatchedList(playedDuration);
    }
  }, [playedDuration]);

  const handlePlay = () =>{
    console.log('videoNode');
    const videoPlayer = videoNode.current;
    if ( !playing ) {
      videoPlayer.getInternalPlayer ().pauseVideo ();
    }
    if(host){
      const timeInVideo = videoPlayer.getCurrentTime();
      setPlaying(true);
      console.log('timeInVideo', timeInVideo);
      const message = {
        content: timeInVideo,
        type:'play',
        room: scheduleId,
        userid:userData.id
      };
      sendMessage(message);
    }
  }

  const handlePause = () => {
    const videoPlayer = videoNode.current;
    const timeInVideo = videoPlayer.getCurrentTime();
    setPlaying(false);
    // setPlayed(timeInVideo);
    if(host){
      const message = {
        content: timeInVideo,
        type: 'pause',
        room: scheduleId,
        userid: userData.id
      };
      sendMessage(message);
    }
  }

  function format (seconds) {
    const date = new Date(seconds * 1000)
    const hh = date.getUTCHours()
    const mm = date.getUTCMinutes()
    const ss = pad(date.getUTCSeconds())
    if (hh) {
      return `${hh}:${pad(mm)}:${ss}`
    }
    return `${mm}:${ss}`
  }
  
  function pad (string) {
    return ('0' + string).slice(-2)
  }

  const handleProgress = state => {
    // console.log('state', state);
    setPlayed(state.played);
    setPlayedDuration(state.playedSeconds);
  }

  const handleDuration = (duration) => {
    // console.log('duration', duration);
    setDuration(duration);
  }

  const handleEnded = () => {
    console.log('onEnded');
    const message = {
      content: played,
      type:'end',
      room: scheduleId,
      userid:userData.id
    };
    sendMessage(message);
  }

  const mureUnmuteVideo = () => {
    setIsMuted(!isMuted);
  }


  return (
    scheduleId?
      schedule?
      <>
        {/* {console.log(schedule, new Date(schedule.scheduled_date), '    ', new Date(new Date().toUTCString()))} */}
        {
          new Date(schedule.scheduled_date).getTime() <= new Date(new Date().toUTCString()).getTime()?
            <>
              {
                loading?
                  <div className='spin'><Spin /></div>
                :null
              }
              <ReactPlayer
                ref={videoNode}
                controls={false}
                playsinline={true}
                config={{
                  vimeo:{
                    iframeParams: { fullscreen: 1, playsinline:0, autopause: false }
                  },
                  youtube: {
                    playerVars: {
                      enablejsapi : 1,
                      playsinline: 0,
                      // autoplay: 0,
                      controls: 0,
                      rel : 0,
                      showinfo : 0,
                      showsearch : 0,
                      // mute : 1,
                      modestbranding : 1,
                      disablekb : 1,
                      // loop : 1,
                      origin: window.location.href
                    }
                  }
                }}
                muted={isMuted}
                // autoplay={true}
                playing={playing}
                onPlay={handlePlay}
                className={className}
                width={window.innerWidth}
                height={window.innerHeight}
                onReady={(data) => {
                  setLoading(false);
                  console.log('ON Ready');
                }}
                onStart={onStart}
                onEnded={handleEnded}
                onDuration={handleDuration}
                onSeek={onSeek}
                onProgress={handleProgress}
                onPause={handlePause}
                url={videoUrl}
              />
              <div style={{
                position: 'absolute',
                zIndex: 100000,
                bottom: 0,
                width: '100%',
                height: '100%'
              }}>
                
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    position: 'absolute',
                    bottom: 0,
                    padding: 6,
                    gap: 6,
                    background: '#00000030'
                  }} className="player-controls-overlay">
  
                      {/* onClick={ () => {
                        if(playing){
                          handlePause();
                        }else{
                          handlePlay();
                        }
                      }} */}
                    
  
                      <Button disabled={!host} style={{ background: 'transparent', color: '#ec6a40', border: 0 }} onClick={()=>{
                        if(playing){
                          setPlaying(false);
                        }else{
                          setPlaying(true);
                        }
                      }}
                        icon={
                          playing?
                            <FeatherIcon icon="pause-circle" />
                          :<FeatherIcon icon="play-circle" />
                        }
                       />
                      <div className="progress-bar" style={{width: '100%' }}>
                        
                        <Slider disabled={!host} defaultValue={0} value={duration * played}   min={0} max={duration} step={1} style={{ margin: '0 16px' }} onChange={onChange} onAfterChange={onAfterChange} />
                        <span className='timer'>{format(duration * played)}</span>
                      </div>   
                      {
                        videoUrl && videoUrl.includes('vimeo.com')?
                          null
                        :<Button style={{ background: 'transparent', color: '#ec6a40', border: 0, padding: '4px 10px', width: 50 }} onClick={()=>{
                            mureUnmuteVideo();
                          }}
                            icon={
                              isMuted?
                              <FeatherIcon icon="volume-x" />
                              :<FeatherIcon icon="volume-2" />
                            
                            }
                          />
                      }                   
                      
                  </div>
              </div>
            </>
          :<div style={{padding: 20, display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', color: '#ffffff', backgroundColor:'#000' }}>
            {
              schedule && schedule.scheduled_by == userData.id?
                <h4 style={{ color: '#ffffff', fontSize: '1.6rem' }}>You have scheduled this film at {moment(schedule.scheduled_date).format('DD/MM/YYYY hh:mm A')}. As a host if you are early, you must wait till your screen date and time to begin your film screening.</h4>
              :<h4 style={{ color: '#ffffff', fontSize: '1.6rem' }}>Your film screening will start once your host arrives and plays the film.</h4>
            }
          </div>
        }
      </>
      :null
    :<ReactPlayer
      ref={videoNode}
      controls={true}
      className={className}
      width={window.innerWidth-10}
      playsinline={true}
      height={window.innerHeight-5}
      onReady={() => console.log('onReady Single')}
      onEnded={handleEnded}
      onDuration={handleDuration}
      onProgress={handleProgress}
      url={videoUrl}
    />
  );
};

export default Player;
