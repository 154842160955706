import React, { useContext, useEffect, useState } from 'react';
import { Input, Button, Avatar, Menu, Dropdown, Badge } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { NotificationOutlined, MessageOutlined } from '@ant-design/icons';
import { SearchBox } from './style';
import config from '../config/config';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useRouteMatch, useHistory } from 'react-router-dom';
import { getUnreadtNotification } from '../redux/notification/actionCreator';
import { getUserById } from '../redux/user/actionCreator';
import { SocketContext } from '../socketProvider';

const { theme, images } = config;
const { Search } = Input;

const HeaderContent = ({ showLogin, showDrawer, onSearch, setSearch, onSearchClear, searchText, setSearchBar, SignOut }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const socketIo = useContext(SocketContext);
  const { userData, filterData, notification } = useSelector(state => {
    return {
      userData: state.auth.data,
      filterData: state.filter.data,
      notification: state.unreadnotification.data
    };
  });
  const routeMatch = useRouteMatch();
  const pathName = window.location.pathname;
  const [unreadCount, setUnreadCount] = useState(0);
  const [path, setPath] = useState(window.location.pathname);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setPath(window.location.pathname);
      setSearchBar(window.location.pathname === '/search');
    }
    return () => (unmounted = true);
  }, [setPath, routeMatch]);

  const getNotifiction = async(user) => {
    if(user && user.id){
      let returndata = await dispatch(getUnreadtNotification());
    }
  };

  
  const checkUserExpire = async (user) => {
    let returndata = await dispatch(getUserById(user));
    if(returndata && returndata.type == 'USER_SUCCESS'){
      if(returndata.data){
        
      }else{
        SignOut();
      }
    }else{
      SignOut();
    }
  }

  useEffect(() => {
    if(userData){
      checkUserExpire(userData);
      getNotifiction(userData);
    }

  }, [userData]);


  useEffect(() => {
    // console.log('socketIo == socketIo', socketIo);
    if(socketIo && socketIo.id){

      socketIo.emit('updateSocketId', {
          socket_id: socketIo.id,
          user_id: userData?.id
      });
      socketIo.emit("roomsList", {
          user_id: userData?.id,
          socket_id: socketIo.id
      });

      socketIo.on("rooms", (rooms) => {
          // console.log('rooms',  rooms);
          // setRooms(rooms);
          let counts = 0;
          rooms.forEach(element => {
              counts = counts+ Number(element.messageCount);
          });
          setUnreadCount(counts);
      });

      socketIo.on("new_message", (msg) => {
        // console.log('msg  new_message', msg);
          // socketIo.emit("unread_messages");
          socketIo.emit("roomsList", {
              user_id: userData.id,
              socket_id: socketIo.id
          });
      });
    }
  }, [socketIo, socketIo.connected]);

  const gotoProfile = () => {
    history.push('/profile');
  }

  // useEffect(() => {
  //   console.log('filterData', filterData);
  //   let unmounted = false;
  //   if (!unmounted) {
  //     onSearch(searchText, filterData)
  //   }
  //   return () => (unmounted = true);
  // }, [filterData]);

  

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <a href="https://www.antgroup.com">1st menu item</a>
      </Menu.Item>
      <Menu.Item key="1">
        <a href="https://www.aliyun.com">2nd menu item</a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="3">3rd menu item</Menu.Item>
    </Menu>
  );

  return (
    <>
      <NavLink to="/" className="logo">
        <img src={images.Logo} alt="CinemaWalla Team" />
      </NavLink>
      {
        path === '/search'?
          window.innerWidth >= 992?
            <SearchBox>
              <Input value={searchText} onChange={(event)=> {
                setSearch(event.target.value);
                onSearch(event.target.value);
                
              } } addonBefore={<FeatherIcon icon="search" />} addonAfter={searchText !='' ?<Button onClick={() =>  onSearchClear()} type="text" style={{height: 30}} icon={<FeatherIcon icon="x" />} />:null} />
            </SearchBox>
          :null
        :null
      }
      <div className="header-right">
        {
          // window.innerWidth >= 992?
            path !== '/search'?
              <NavLink style={{ display: 'flex', color:"#929292" }} to={'/search'} type="text" ><FeatherIcon icon="search" /></NavLink>
            :null
          // :<Button onClick={ ()=> this.setState({searchHide:!searchHide}) } type="text" icon={<FeatherIcon icon="search" />} />
        }
        {
          userData && userData.id?
            <Avatar onClick={()=> gotoProfile() } size={40} className="user-profile" src={userData.profileImage?userData.profileImage:null}>{`${userData.firstName?userData.firstName.charAt(0):''} ${userData.lastName?userData.lastName.charAt(0):''}`}</Avatar>
          // <Dropdown  overlay={menu} trigger={['click']}>
            
          // </Dropdown>
          :<Button onClick={ ()=> showLogin() } type="primary" shape="round" style={{background:theme['primary-color'], borderColor:theme['primary-color']}} >Login</Button>
        }
        {
          userData && userData.id?
            <NavLink style={{display:'flex', color:"#929292"}} to="/notification">
              <Badge dot={notification.length > 0} >
                <NotificationOutlined style={{ fontSize: 22, color:"#929292", marginLeft:0, marginRight:0 }} />
              </Badge>
            </NavLink>
          :null
        }
        {
          userData && userData.id?
            <NavLink style={{display:'flex', color:"#929292"}} to="/messages">
              <Badge dot={unreadCount > 0} >
                <MessageOutlined style={{ fontSize: 22, color:"#929292", marginLeft:0, marginRight:0 }} />
              </Badge>
            </NavLink>
          :null
        }
       
        <Button onClick={ ()=> showDrawer() } type="text" icon={<FeatherIcon icon="menu" />} />
      </div>
    </>
  );
};

export default HeaderContent;
