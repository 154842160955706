import Styled from 'styled-components';
import { themeColors } from '../../config/theme/themeVariables';

const LoginContainer = Styled.div`
    width:100%;
    // *{
    //     color:#fff;
    // }
    .ant-input{
        color:#000;
    }
    .login-form-forgot, .ant-checkbox-wrapper, .signup-link{
        color:#fff;
    }
    .heading{
        color:#fff;
        font-size:26px;
        text-align: center;
        margin-bottom: 30px;
    }
    .downlaod-heading{
        color: #fff;
        font-size: 14px;
        text-align: center;
        margin-bottom: 10px;
        border-top: 1px solid #fff;
        padding-top: 16px;
    }
    button{
        width: 100%;
        background:${themeColors.primaryColor};
        color: ${themeColors.white};
        border: 0;
        height: 44px;
        box-sizing: border-box;
        &:hover{
            background:${themeColors.primaryColor};
            color: ${themeColors.white};
        }
    }
    .signup-link{
        text-align:center;
        font-size: 12px;
        a{
            color: ${themeColors.primaryColor};
            font-weight: 600;
            font-size: 14px;
        }
    }
    
`

export { LoginContainer };
