/* eslint-disable no-shadow */
import React, { Component } from 'react';
import { Layout, Input, Drawer, Button, Modal } from 'antd';
import 'antd/dist/antd.css';
import FeatherIcon from 'feather-icons-react';
import { NavLink, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import MenueItems from './MenueItems'; 
import { Div, Container, SearchBox, SearchBar } from './style';
import { logOut } from '../redux/authentication/actionCreator';
import { setSearchText } from '../redux/search/actionCreator';
import Login from '../components/Login';
import SchedulePopup from '../components/Scheduling';
import HeaderContent from './Header';
import { messaging } from '../config/firebase';
// import { connectSocket } from '../redux/messages/actionCreator';
const { Header, Content, Footer } = Layout;
// const { darkMode } = config;


const ThemeLayout = WrappedComponent => {
  class LayoutComponent extends Component {
    
    constructor(props) {
      super(props);
      this.state = {
        collapsed: false,
        loginPage: false,
        visible:false,
        searchText:'',
        searchHide: false,
        customizerAction: false,
        activeSearch: false,
      };
      this.path = window.location.pathname;
      this.updateDimensions = this.updateDimensions.bind(this);
      this.onClose = this.onClose.bind(this);
      this.onCloseLogin = this.onCloseLogin.bind(this);
      this.setSearch = this.setSearch.bind(this);
      this.onSearchClear = this.onSearchClear.bind(this);
      this.showLogin = this.showLogin.bind(this);
      this.showDrawer = this.showDrawer.bind(this);
      this.setSearchBar = this.setSearchBar.bind(this);
      
    }

    showDrawer(){
      this.setState({visible:true});
    };
 
    showLogin(){
      this.setState({loginPage:true});
    };

    onClose(){
      this.setState({visible:false});
    };

    setSearchBar(status){
      this.setState({searchHide:status});
    }

    onCloseLogin(){
      this.setState({loginPage:false});
    }

    showMessage(userInfo){
      if(userInfo.profileComplete){
  
      }else{
        Modal.info({
          title: 'Please complete your profile to submit films',
          onOk() {
          },
        });
        // modal.info({title: 'Please complete your profile to suit films'});
        // message.info({ content:  'To became a "Film Maker" complete your profile', duration: 2 });
      }
     
    }

    async setNotificationSetting(){
      messaging.getToken({vapidKey: 'GENERATED_MESSAGING_KEY'}).then((currentToken) => {
        if (currentToken) {
          console.log('current token for client: ', currentToken);
          sessionStorage.setItem('cinemawallaWebFCMToken', currentToken);
          // Track the token -> client mapping, by sending to backend server
          // show on the UI that permission is secured
        } else {
          console.log('No registration token available. Request permission to generate one.');
          // shows on the UI that permission is required 
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // catch error while creating client token
      });
      // navigator.serviceWorker.addEventListener("message", (message) => console.log('fb-message', message));
    }

    onMessageListener = () =>
      new Promise((resolve) => {
        messaging.onMessage((payload) => {
          console.log('payload', payload);
        });
    });

    componentDidMount () {
      window.addEventListener('resize', this.updateDimensions);
      this.updateDimensions();
      // this.props.onConnectSocket();
      let name ='';
      // console.log('props', this.props.userData);
      if(this.props.userData && this.props.userData.firstName){
        name = this.props.userData.firstName+' '+this.props.userData.lastName;
      }

      // const Token = localStorage.getItem('cinemawallaWebUserMatrix')?JSON.parse(window.atob(localStorage.getItem('cinemawallaWebUserMatrix'))):null;
      // const Token = localStorage.getItem('cinemawallaWebUserMatrix')?JSON.parse(window.atob(localStorage.getItem('cinemawallaWebUserMatrix'))):null;
      // console.log('Token', Token);
      // if(Token && Token.access_token){
        // Matrix.initClient(Token, name);
      // }
      // this.setNotificationSetting();
      // this.onMessageListener();
      // this.path = window.location.pathname;


    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions() {
      this.setState({
        collapsed: window.innerWidth <= 1200 && true,
      });
    }

    SignOut = e => {
      // console.log(e);
      // e.preventDefault();
      this.props.signOut();    
      this.props.history.push('/')  
    };

    onSearchClear() {
      this.setState({searchText:''});
      this.props.onSearch('');
    }

    setSearch (value) {
      this.setState({searchText:value});
    }

    // onSearch = async (value) => {
    //   console.log('filterData', this.props.filterData);
    //   this.setState({searchText:value});
    //   console.log(value);
    //   // let returndata = await dispatch(getSearch({search_value:value}));
    //   // // console.log(returndata);
    //   // if(returndata){
    //   //   if(returndata.type=="SEARCH_SUCCESS"){
    //   //     // setMovies(returndata.data);
    //   //   }else if(returndata.type=="SEARCH_ERR"){
          
    //   //   }
    //   // }
    // }
    //userData.institution && userData.institution.status && userData.institution.status!=''?false:true;

    render() {
      return (
        <Div>
          <Drawer className="login-drawer" placement="top" height={'100vh'} onClose={this.onCloseLogin} visible={this.state.loginPage}>
            <div className="login-container">
              <Login close={this.onCloseLogin} />
            </div>
          </Drawer>
          <Header className="header">
            <HeaderContent SignOut={this.SignOut} showLogin={this.showLogin} setSearchBar={this.setSearchBar} onSearch={this.props.onSearch} onSearchClear={this.onSearchClear} searchText={this.state.searchText} setSearch={this.setSearch} showDrawer={this.showDrawer} />
          </Header>
          {
            window.innerWidth < 992 && this.state.searchHide?
              <SearchBar>
                <Input value={this.state.searchText} onChange={(event)=> { this.setSearch(event.target.value); this.props.onSearch(event.target.value); } } addonBefore={<FeatherIcon icon="search" />} addonAfter={this.state.searchText !='' ?<Button onClick={() =>  this.onSearchClear()} type="text" style={{marginRight: 16}} icon={<FeatherIcon icon="x" />} />:null} />
              </SearchBar>
            :null
          }
          <Content className="site-layout" style={{ marginTop: 64, position:'relative' }}>
            <WrappedComponent {...this.props}  />
            <SchedulePopup />
            <Drawer className="menu-drawer" placement="right" width={300} onClose={this.onClose} visible={this.state.visible}>
              <MenueItems SignOut={this.SignOut} toggleCollapsed={this.onClose} showMessage={this.showMessage} />
            </Drawer>
          </Content>
          <Footer className="footer">
            <Container>
            <div className="footer-left">
              <p>© 2022 Cinemawalla.com. All Rights Reserved.</p>
            </div>
            <div className="footer-right">
              <NavLink to="/privacy-policy">Privacy Policy</NavLink>
              <NavLink to="/terms-service">Terms of Service</NavLink>
            </div>
            </Container>
          </Footer>
        </Div>
      );
    }
  }

  const mapStateToProps = state => {
    return {
      signOut: state.signOut,
      userData: state.auth.data,
      filterData: state.filter.data,
    };
  };

  const mapStateToDispatch = dispatch => {
    return {
      signOut: () => dispatch(logOut()),
      onSearch: (text) => dispatch(setSearchText(text)),
      // onConnectSocket: () => dispatch(connectSocket()),
    };
  };

  return connect(mapStateToProps, mapStateToDispatch)(LayoutComponent);
};
export default ThemeLayout;
