import React, { lazy, Suspense, useEffect, useState  } from 'react';
import { Collapse, Rate, Tag, Row, Col, Button, Avatar, Image, Typography, message, Space, Modal, Form, Input, Card, DatePicker, TimePicker  } from 'antd';
import moment from 'moment';
import { AddMemberSection, AddCinephileSection, ScheduledFilmsSection, CinephileFriendSection, CustomeModelStyle } from './style';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import AddIcon from '../../assets/images/Add-circle.png';
import { useSelector, useDispatch } from 'react-redux';
import { setScheduleMovie, updateScheduleMovie } from '../../redux/movies/actionCreator';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { ArrayFieldcount } from '../../utility/utility';
import config from '../../config/config';
import { getFriends } from '../../redux/user/actionCreator';
const { theme, images, imagePath } = config;

const SchedulePopup = () => {
    const { userData, SchadulePopup } = useSelector(state => {
        return {
            userData: state.auth.data,
            SchadulePopup: state.SchadulePopup.data
        };  
    });
    const { path, params  } = useRouteMatch();
    const dispatch = useDispatch();
    const history = useHistory();
    const format = 'hh:mm A';
    const [form] = Form.useForm();
    const [group, setGroup] = useState([]);
    const [fields, setFields] = useState([]);
    const [groupIds, setGroupIds] = useState([]);
    const [scheduledDate, setscheduledDate] = useState(null);
    const [scheduledTime, setscheduledTime] = useState(null);
    const [scheduled, setScheduled] = useState({});
    const [showHideFriendList, setShowHideFriendList] = useState(true);
    const [seatchText, setSeatchText] = useState(null);
  
    const [friends, setFriends] = useState([]);

    const { Title, Text, Paragraph  } = Typography;
  
    const onChangeDate = (date, dateString) => {
      console.log(date, dateString);
      setscheduledDate(date);
    }
  
    const onChangeTime = (time, timeString) => {
      console.log(time, timeString);
      setscheduledTime(timeString);
    }
  
    const [isModalVisible, setIsModalVisible] = useState(null);
    const { TextArea } = Input;
  
    const showModal = (key) => {
      if(userData && userData.id){
        setIsModalVisible(key);
      }else{
        history.push('/registration');
      }
       
    };
  
    const handleOk = () => {
      setIsModalVisible(false);
    };
  
    const handleCancel = () => {
      setIsModalVisible(null);
    };
  
    const getGroupedData = (data, searchtext, group) => {
      let filterdata = [];
      if(filterdata.length===0){
        filterdata = data;
      }
      const ids = group.map(el => el.friend_id);
      if(searchtext){
        filterdata = data.filter(function(item) {
          const itemData = item && item.firstName ? item.firstName.toUpperCase() : '';
          const textData = searchtext? searchtext.toUpperCase() : '';
          return itemData.indexOf(textData) > -1;
        });
      }
      filterdata = filterdata.filter(item =>  !ids.includes(item._id) );
      return filterdata;
    }
    
    const fatchFriends = async(id) => {
        let returndata = await dispatch(getFriends(id))
        if(returndata){
        if(returndata.type=="USER_SUCCESS"){
          const newList = returndata.data.filter(el=> el._id !== id);
          setFriends(newList);
        }else if(returndata.type=="USER_ERR"){
            
        }
        }
    }

    const addInGroup = async (data) => {
        if(group.length === 6){
          message.info({ content:'Max limit is 6 member in a group', duration: 2 })
        }else{
          // let postData = {
          //   user_id: userData.id,
          //   status: 0,
          //   friend_id: data._id,
          //   email: data.email
          // }
          // let returndata = await dispatch(addFriends(postData))
          // if(returndata){
          //   if(returndata.type=="USER_UPDATE_SUCCESS"){
          //     group.push({
          //       firstName:data.firstName,
          //       lastName: data.lastName,
          //       friend_id: returndata.data._id,
          //       status: 0,
          //     });
          //     setGroup([...group]);
          //     setGroupIds([...groupIds, data._id]);
          //   }else if(returndata.type=="USER_UPDATE_ERR"){
          //   }
          // }
          group.push({
            firstName:data.firstName,
            lastName: data.lastName,
            email: data.email,
            friend_id: data._id,
            status: 0,
          });
          setGroup([...group]);
          setGroupIds([...groupIds, data._id]);
        }
      }
    
      const addNewUser = async (data) => {
        
        if(group.length === 6){
          message.info({ content:'Max limit is 6 member in a group', duration: 2 })
        }else{
          // data['user_id'] = userData.id;
          // data['status'] = 0;
          // let returndata = await dispatch(addFriends(data))
          // if(returndata){
          //   // console.log(returndata);
          //   if(returndata.type=="USER_UPDATE_SUCCESS"){
          //     group.push({
          //       firstName:data.name.split(' ')[0],
          //       lastName: data.name.split(' ').length>1? data.name.split(' ')[1]:'',
          //       friend_id: returndata.data._id,
          //       status: 0,
          //     });
          //     setGroup([...group]);
          //   }else if(returndata.type=="USER_UPDATE_ERR"){
          //   }
          // }
          group.push({
            firstName:data.name.split(' ')[0],
            lastName: data.name.split(' ').length>1? data.name.split(' ')[1]:'',
            email: data.email,
            status: 0,
          });
          setGroup([...group]);
        }
        setShowHideFriendList(true);
      }

    const friendSearch = (event) => {
        setSeatchText(event.target.value);
    }
    
      const scheduleMovie = async(data) =>{
        // const Ids = grou
        if(group.length>1){
          let now = new Date();
          now.setMinutes(now.getMinutes() + 18); 
          if(new Date(moment(scheduledDate).format('YYYY/MM/DD')+' '+scheduledTime).getTime() >= new Date(now).getTime()){
            
            if(SchadulePopup.data && SchadulePopup.data._id){
              const postData = {
                _id:SchadulePopup.data._id,
                scheduled_date:new Date(moment(moment(scheduledDate).format('YYYY/MM/DD')+" "+scheduledTime).format('YYYY/MM/DD hh:mm A')).toUTCString(),
                scheduled_time: scheduledTime,
                scheduled_by:userData.id,
                movieId:SchadulePopup.id,
                scheduled_with:group
              }
              setScheduled({...postData});
              let returndata = await dispatch(updateScheduleMovie(postData))
              if(returndata){
                // console.log(returndata);
                if(returndata.type=="MOVIE_UPDATE_SUCCESS"){
                  handleCancel();
                  history.push('/scheduled');
                }else if(returndata.type=="MOVIE_UPDATE_ERR"){
                }
              }
            }else{
              const postData = {
                scheduled_date:new Date(moment(moment(scheduledDate).format('YYYY/MM/DD')+" "+scheduledTime).format('YYYY/MM/DD hh:mm A')).toUTCString(),
                scheduled_time: scheduledTime,
                scheduled_by:userData.id,
                movieId:SchadulePopup.id,
                scheduled_with:group
              }
              setScheduled({...postData});
              let returndata = await dispatch(setScheduleMovie(postData))
              if(returndata){
                // console.log(returndata);
                if(returndata.type=="ADD_MOVIE_SUCCESS"){
                  handleCancel();
                  history.push('/scheduled');
                }else if(returndata.type=="ADD_MOVIE_ERR"){
                }
              }
            }
            
          }else{
            message.info({ content:'Time should be after 20 min from now time', duration: 2 })
          }
        }else{
          message.info({ content:'Please select friends to schedule movie', duration: 2 })
        }
        // console.log(scheduled);
       
    
        
      }

      const removeUser = (index, id) => {
        // console.log(group, groupIds);
        group.splice(index,1);
        setGroup([...group]);
        // if(id){
        //   groupIds.filter(el => el=> );
        //   setGroupIds([...groupIds, data._id]);
        // }

      }

      useEffect(() => {
        if(SchadulePopup && SchadulePopup.type){
            // console.log(SchadulePopup);
            showModal(SchadulePopup.type);
            setGroup([]);
            if(SchadulePopup.data){
              let dataform =[
                {name:'date-picker', value:moment(SchadulePopup.data.scheduled_date)},
                {name:'time-picker', value:moment(SchadulePopup.data.scheduled_date)}
              ];
              setFields(dataform);
              setscheduledDate(moment(SchadulePopup.data.scheduled_date));
              setscheduledTime(moment(SchadulePopup.data.scheduled_date).format('hh:mm A'));
              SchadulePopup.data.scheduled_with.map(el=>{
                group.push({...el});
              });
              setGroup([...group]);
            }
            if(userData && userData.id){
              fatchFriends(userData.id);
              const index = group.findIndex(el=> el.friend_id === userData.id);
              if( index < 0 ){
                group.push({
                  firstName:userData.firstName,
                  lastName: userData.lastName,
                  email: userData.email,
                  friend_id: userData.id,
                  status: 1,
                });
                setGroup([...group]);
              }
            }
        }
      }, [SchadulePopup]);
      
      

    return (
        <>
            <Modal className="addMember" footer={null} width={411} visible={isModalVisible === "addMemberModel"} onOk={handleOk} onCancel={()=>handleCancel("addMemberModel")}>
                <AddMemberSection>
                    <Title level={4} className="modelTitle">Add me as a crew member</Title>
                    <Title level={2} className="chapterName">Chapter 3</Title>
                    <Row style={{marginBottom:30}}>
                    <Col span={12}>
                        <p style={{marginBottom:5,fontSize:15,color:"#2C4349"}}>Released In</p>
                        <b style={{fontSize:18,color:"#2C4349"}}>2000</b>
                    </Col>
                    <Col span={12}>
                        <p style={{marginBottom:5,fontSize:15,color:"#2C4349"}}>Language</p>
                        <b style={{fontSize:18,color:"#2C4349"}}>Hindi</b>
                    </Col>
                    </Row>

                    <Row>
                    <Col span={24}>
                        <Form className="crewMemberForm">
                        <Form.Item label="Real Name">
                            <Input className="modelInput"/>
                        </Form.Item>
                        <Form.Item label="Email id">
                            <Input className="modelInput"/>
                        </Form.Item>
                        <Form.Item label="My Role">
                            <Input className="modelInput"/>
                        </Form.Item>
                        <Form.Item label="Add Note">
                            <Input className="modelInput"/>
                        </Form.Item>

                        <Button type="primary" shape="round" className="addMeBtn">Add Me</Button>
                        </Form>
                    </Col>
                    </Row>
                </AddMemberSection>
            </Modal>



            <Modal footer={null} maskClosable={false} visible={isModalVisible === "scheduledFilms"} onOk={handleOk} onCancel={()=>handleCancel("scheduledFilms")}>
                <ScheduledFilmsSection>
                <Form className="scheduledFilms" form={form} fields={fields} onFinish={scheduleMovie}>
                <Card style={{background:'#D8E6DC', borderRadius:20, borderColor:'#D8E6DC', marginTop: 30}}>
                    <Title level={4} className="modelTitle">List of Scheduled Films with Friends</Title>
                    <Form.Item name="date-picker" rules={[{ required: true, message: 'Please Choose Date' }]}>
                    <DatePicker onChange={onChangeDate} value={scheduledDate} className="datePiker"/>
                    </Form.Item>
                    <Form.Item name="time-picker"  rules={[{ required: true, message: 'Please Choose Time' }]}>
                    <TimePicker style={{marginBottom:20}} use12Hours={true} value={scheduledTime}  minuteStep={15} onChange={onChangeTime} className="datePiker" format={format} />
                    </Form.Item>
                </Card>

                <Card style={{background:'#FDE4C7', borderRadius:20, borderColor:'#FDE4C7', marginTop: 15}}>
                    <Title level={4} className="modelTitle">Send Invite to Your Cinephile Friends</Title>
                    <Row style={{marginTop:10, marginBottom:0}}>
                    <Col span="16">
                        <p>Select friends from friend list</p>
                    </Col>
                    <Col span="8" style={{textAlign:'right'}}>
                        <button type="button" style={{background:'transparent', border:'none'}} onClick={()=>showModal("cinephileFriends")}>
                        <img style={{width:35}} src={AddIcon}></img>
                        </button>
                    </Col>
                    </Row>
                    {
                        group && group.length > 0?
                        <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                        <Col span={24}>
                            <Row>
                            {
                                group.map((friend, index)=>( 
                                  friend.friend_id === userData.id?
                                        null
                                      :
                                    <Col key={friend.firstName+'-'+index} style={{ margin:'8px 2px' }}>
                                    <Avatar size={40} src={friend && friend && friend.profileImage ? imagePath + friend.profileImage : null} > { friend && friend? `${friend.firstName?friend.firstName.charAt(0):''} ${friend.lastName?friend.lastName.charAt(0):''}` :'' } </Avatar>
                                    </Col>
                                ))
                            }  
                            </Row>
                        </Col>
                        </Row>
                        :null
                    }
                </Card>

                {/* <Button type="primary" shape="round" className="scheduleBtn" style={{background:'#6488E4', borderColor:'#6488E4', width:'100%'}}>Send Invite to Your Cinephile Friends</Button> */}
                <Button htmlType="submit" type="primary" shape="round" className="scheduleBtn" style={{background:'#DF6438', borderColor:'#DF6438', width:'100%'}}>Schedule This Film</Button>
                </Form>
                </ScheduledFilmsSection>
            </Modal>


            <Modal footer={null} maskClosable={false} visible={isModalVisible === "cinephileFriends"} onOk={handleOk} onCancel={()=>{handleCancel("cinephileFriends"); showModal("scheduledFilms"); setShowHideFriendList(true);}}>
                {
                showHideFriendList?
                <CinephileFriendSection>
                    <Title level={2} className="modelTitle">Cinephile Friends List</Title>
                    <Form className="cinephileFriends">
                        <Row>
                        <Col span="16">
                            <Input className="searchInput" size="large" onChange={friendSearch} placeholder="Search" prefix={<SearchOutlined />} />
                        </Col>
                        <Col span="8" style={{textAlign:'right'}}>
                            <Button onClick={()=> setShowHideFriendList(false) } className="buttonAddFriend" type="primary" shape="round">Add Friend  <img style={{width:35, marginTop:-5}} src={AddIcon}></img></Button>
                        </Col>
                        </Row>
                        {
                            group && group.length > 0?
                            <Row style={{ justifyContent: 'space-between', alignItems: 'center', padding: '10px 0' }}>
                            <Col span={16}>
                                <Row>
                                {
                                    [...group].map((friend, index)=>( 
                                      friend.friend_id === userData.id?
                                        null
                                      :<Col key={friend.firstName+'-'+index} style={{ margin:'8px 2px', position: 'relative' }}>
                                        <Avatar size={40} src={friend && friend && friend.profileImage ? imagePath + friend.profileImage : null} > { friend && friend? `${friend.firstName?friend.firstName.charAt(0):''} ${friend.lastName?friend.lastName.charAt(0):''}` :'' } </Avatar>
                                        <CloseOutlined onClick={() => removeUser(index, friend.friend_id)} style={{ background: '#ef3900', padding: '2px', borderRadius: '50px', color: '#ffffff', position: 'absolute', top: '-5px', right: '-5px' }} />
                                        </Col>
                                    ))
                                }  
                                </Row>
                            </Col>
                            <Col span={8}>
                                <Button onClick={()=>{handleCancel("cinephileFriends"); showModal("scheduledFilms");}} type="primary" shape="round" className="
                                scheduleFilm">Schedule Film</Button>
                            </Col>
                            </Row>
                            :null
                        }
                        

                        <Row gutter={16} style={{height:320, overflowY:'scroll', marginTop:15}}>
                        {
                            friends && friends.length > 0?
                            getGroupedData(friends, seatchText, group).map((friend, index)=>( 
                                <Col key={friend.firstName+'-'+index} xs={24} md={8}>
                                <Card className="friend-card">
                                    <Avatar size={85} src={friend && friend.profileImage ? imagePath + friend.profileImage : null} > { friend ? `${friend.firstName?friend.firstName.charAt(0):''} ${friend.lastName?friend.lastName.charAt(0):''}` :'' } </Avatar>
                                    <div className="userName">{friend.firstName+' '+friend.lastName}</div>
                                    <div className="counteryName">{friend.country}</div>
                                    {/* <div className="counteryName">(Maker)Director/ User</div> */}
                                    <Button onClick={()=> addInGroup(friend)} type="primary" shape="round" className="selectBtn">Select</Button>
                                </Card>
                                </Col>
                            ))
                            
                            :null
                        }
                        
                        
                        </Row>

                        
                    </Form>
                    
                </CinephileFriendSection>
                :<AddCinephileSection>
                <Title level={4} className="modelTitle">Add  a new Cinephile Friend</Title>
                    <Form className="memberForm" onFinish={addNewUser}>
                    <Form.Item label="Full Name" name="name" style={{ margin: 20 }} rules={[{ required: true, message: 'Please enter full name' }]}>
                        <Input className="modelInput"/>
                    </Form.Item>
                    <Form.Item label="Email id" name="email" style={{ margin: 20 }} rules={[{ required: true, type:'email', message: 'Please enter email' }]}>
                        <Input className="modelInput"/>
                    </Form.Item>
                    <Button htmlType="submit" type="primary" shape="round" className="addMeBtn">Add as a Friend</Button>
                    </Form>
                </AddCinephileSection>
                }
                
                
            </Modal>
        </>
    );
};

export default SchedulePopup;
