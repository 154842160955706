const actions = {
  
    USER_BEGIN: 'USER_BEGIN',
    USER_SUCCESS: 'USER_SUCCESS',
    USER_ERR: 'USER_ERR',

    USER_UPDATE_BEGIN: 'USER_UPDATE_BEGIN',
    USER_UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',
    USER_UPDATE_ERR: 'USER_UPDATE_ERR',
  
    userBegin: () => {
      return {
        type: actions.USER_BEGIN,
      };
    },
  
    userSuccess: data => {
      return {
        type: actions.USER_SUCCESS,
        data,
      };
    },
  
    userErr: err => {
      return {
        type: actions.USER_ERR,
        err,
      };
    },

    userUpdateBegin: () => {
      return {
        type: actions.USER_UPDATE_BEGIN,
      };
    },
  
    userUpdateSuccess: data => {
      return {
        type: actions.USER_UPDATE_SUCCESS,
        data,
      };
    },
  
    userUpdateErr: err => {
      return {
        type: actions.USER_UPDATE_ERR,
        err,
      };
    },
  
  };
  
  export default actions;
  