import Styled from 'styled-components';
import { themeColors } from '../../config/theme/themeVariables';


const AddCinephileSection = Styled.div`
    .modelTitle{
        font-size:16px;
        font-weight: 700;
        color:#2C4349;
    }
    label{
        display:block;
        height: auto;
        font-size:15px;
        margin-top:0px;
    }
    .ant-form-item-control {
        display: block;
        width: 100%;
        flex: none;
    }
    .modelInput{
        display:block;
        border: none;
        border-bottom: 1px solid #cacaca;
        border-radius: 0;
        background: transparent;
    }
    .addMeBtn{
        border-color:#E46472;
        margin-top:5px;
        padding-left:30px;
        padding-right:30px;
        height:50px;
        background:#E46472;
        width:100%;
        font-size:14px;
        font-weight: 700;
    }


`

const ScheduledFilmsSection = Styled.div`
    .modelTitle{
        font-size:16px;
        font-weight: 700;
        margin-bottom:0;
        color:#2C4349;
    }
    .datePiker{
        width: 100%;
        margin-top: 15px;
        background: transparent;
        border: none;
        border-bottom: 1px solid #AEB5B1;
    }
    .scheduleBtn{
        font-size:14px;
        font-weight: 700;
        height: 46px;
        margin-top: 15px;
    }

`

const CinephileFriendSection = Styled.div`
    .modelTitle{
        font-size:32px;
        font-weight: bold;
        color:#4E5053;
    }
    .searchInput {
        border: none;
        border-bottom: 1px solid #EBE8DE;
        border-radius: 0;
        background: transparent;
        input{
            background: transparent;
        }
        
    }
    .anticon-search svg {
        display: inline-block;
        fill: #9B9B9B;
    }
    .buttonAddFriend {
        background: transparent;
        border: none;
        box-shadow: none;
        color: #4E5053;
    }
    .userName{
        margin-top:10px;
        margin-bottom:5px;
        font-size:14px;
        font-weight:bold;
    }
    .selectBtn{
        color: #252731;
        background: #fff;
        border-Color: #fff;
        height: auto;
        font-weight: 600;
        font-size: 12px;
        margin-top: 10px;
        padding: 2px 10px;
    }
    .scheduleFilm{
        background:#309397;
    }
    .friend-card{
        background:#D8E6DC;
        border-radius:14px;
        border-color:#D8E6DC;
        margin-bottom: 15px;
        text-align:center;
        .ant-card-body{
            padding:14px;
            text-align: center;
        }
    }
`

const CustomeModelStyle = Styled.div`

`

const AddMemberSection = Styled.div`
    .modelTitle{
        font-size:16px;
        font-weight: 700;
        color:#2C4349;
    }
    .chapterName{
        margin-top:15px !important;
        margin-bottom: 10px;
        font-weight: 800;
        font-size: 32px;
    }
    label{
        display:block;
        height: auto;
        font-size:15px;
        margin-top:0px;
    }
    .ant-form-item-control {
        display: block;
        width: 100%;
        flex: none;
    }
    .modelInput{
        display:block;
        border: none;
        border-bottom: 1px solid #cacaca;
        border-radius: 0;
        background: transparent;
    }
    .addMeBtn{
        border-color:#E46472;
        margin-top:5px;
        padding-left:30px;
        padding-right:30px;
        height:50px;
        background:#E46472;
        width:100%;
        font-size:14px;
        font-weight: 700;
    }
`

export { CustomeModelStyle, CinephileFriendSection, AddMemberSection, ScheduledFilmsSection, AddCinephileSection };
