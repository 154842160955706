import actions from './actions';
import {DataService} from '../../config/dataService/dataService';
import { message } from 'antd';
import axios from 'axios';
import { API } from '../../config/api';
const {
  addMovieBegin,
  addMovieSuccess,
  addMovieErr,

  moviesBegin,
  moviesSuccess,
  moviesErr,

  allMoviesBegin,
  allMoviesSuccess,
  allMoviesErr,

  moviesBulkBegin,
  moviesBulkSuccess,
  moviesBulkErr,

  movieUpdateBegin,
  movieUpdateSuccess,
  movieUpdateErr,

} = actions;


const addSingleMovie = data => {
  return async dispatch => {
    try {
      dispatch(addMovieBegin());
      let response = await DataService.post(`${'movie'}`, data);
      if(response.data.success){
        {response.data.message?
          message.success({ content:  response.data.message, duration: 2 })
        :message.success({ content:  'Record Added Successfully', duration: 2 })}
        return dispatch(addMovieSuccess(response.data.data));
      }else{
        {response.data.message?
          message.error({ content:  response.data.message, duration: 2 })
        :message.success({ content:  'Something went wrong!', duration: 2 })}
        return dispatch(addMovieErr(response.data.message?response.data.message:'Something went wrong!'));
      }
      
    } catch (err) { dispatch(addMovieErr(err));
    }
  };
};

const getMovies = (status, data) => {
  console.log('status, data', status, data);
  return async dispatch => {
    try {
      dispatch(moviesBegin());
      let response;
      if( status === 'favourite' ){
        response = await DataService.get(`fav/user/${data.id}`);
      } else if( status === 'uploaded' ){
        response = await DataService.get(`movie/uploaded/${data.id}`);
      }else if( status === 'reviews' ){
        response = await DataService.get(`movie/reviews/${data.id}`);
      }else{
        response = await DataService.post(`movie/movies/${status}`, {userId:data.id});
      }
      if(response.data.success){
        // {response.data.message && response.data.message.title?
        //   message.success({ content:  response.data.message.title, duration: 2 })
        // :null}
        // {response.data.message && response.data.message.message?
        //   message.success({ content:  response.data.message.message, duration: 2 })
        // :null}
        return dispatch(moviesSuccess(response.data.data));
      }else{
        {response.data.message?
          message.error({ content:  response.data.message, duration: 2 })
        :message.success({ content:  'Something went wrong!', duration: 2 })}
        // {response.data.message && response.data.message.title?
        //   message.error({ content:  response.data.message.title, duration: 2 })
        // :null}
        // {response.data.message && response.data.message.message?
        //   message.error({ content:  response.data.message.message, duration: 2 })
        // :null}
        return dispatch(moviesErr(response.data.message));
      }
      
    } catch (err) { dispatch(moviesErr(err));
    }
  };
};

const getMovieData = (data) => {
  return async dispatch => {
    try {
      dispatch(moviesBegin());
      let response = await DataService.get(`movie/${data}`);
      if(response.data.success){
        // {response.data.message && response.data.message.title?
        //   message.success({ content:  response.data.message.title, duration: 2 })
        // :null}
        // {response.data.message && response.data.message.message?
        //   message.success({ content:  response.data.message.message, duration: 2 })
        // :null}
        return dispatch(moviesSuccess(response.data.data));
      }else{
        {response.data.message?
          message.error({ content:  response.data.message, duration: 2 })
        :message.success({ content:  'Something went wrong!', duration: 2 })}
        // {response.data.message && response.data.message.title?
        //   message.error({ content:  response.data.message.title, duration: 2 })
        // :null}
        // {response.data.message && response.data.message.message?
        //   message.error({ content:  response.data.message.message, duration: 2 })
        // :null}
        return dispatch(moviesErr(response.data.message));
      }
      
    } catch (err) { dispatch(moviesErr(err));
    }
  };
};

const addWatchlist = data => {
  return async dispatch => {
    try {
      dispatch(addMovieBegin());
      let response = await DataService.post(`${'fav'}`, data);
      if(response.data.success){
        {response.data.message?
          message.success({ content:  response.data.message, duration: 2 })
        :message.success({ content:  'Record Added Successfully', duration: 2 })}
        return dispatch(addMovieSuccess(response.data.data));
      }else{
        {response.data.message?
          message.error({ content:  response.data.message, duration: 2 })
        :message.success({ content:  'Something went wrong!', duration: 2 })}
        return dispatch(addMovieErr(response.data.message?response.data.message:'Something went wrong!'));
      }
      
    } catch (err) { dispatch(addMovieErr(err));
    }
  };
};

const addInWatched = (data) => {
  return async dispatch => {
    try {
      dispatch(addMovieBegin());
      let response = await DataService.post(`${'watched'}`, data);
      if(response.data.success){
        return dispatch(addMovieSuccess(response.data.data));
      }else{
        return dispatch(addMovieErr(response.data.message?response.data.message:'Something went wrong!'));
      }
      
    } catch (err) { dispatch(addMovieErr(err));
    }
  };
};

const updateMovie = data => {
  return async dispatch => {
    try {
      dispatch(movieUpdateBegin());
      let response = await DataService.put(`${'movie/'}${data.id}`, data);
      if(response.data.success){
        // {response.data.message?
        //   message.success({ content:  response.data.message, duration: 2 })
        // :message.success({ content:  'Record Added Successfully', duration: 2 })}
        return dispatch(movieUpdateSuccess(response.data.data));
      }else{
        // {response.data.message?
        //   message.error({ content:  response.data.message, duration: 2 })
        // :message.success({ content:  'Something went wrong!', duration: 2 })}
        return dispatch(movieUpdateErr(response.data.message?response.data.message:'Something went wrong!'));
      }
      
    } catch (err) { dispatch(movieUpdateErr(err));
    }
  };
};

const removeWatchlist = data => {
  return async dispatch => {
    try {
      dispatch(movieUpdateBegin());
      let response = await DataService.delete(`${'fav/'}${data.favId}`);
      if(response.data.success){
        {response.data.message?
          message.success({ content:  response.data.message, duration: 2 })
        :message.success({ content:  'Record removeed Successfully', duration: 2 })}
        return dispatch(movieUpdateSuccess(response.data.data));
      }else{
        {response.data.message?
          message.error({ content:  response.data.message, duration: 2 })
        :message.success({ content:  'Something went wrong!', duration: 2 })}
        return dispatch(movieUpdateErr(response.data.message?response.data.message:'Something went wrong!'));
      }
    } catch (err) { dispatch(movieUpdateErr(err));
    }
  };
};

const setScheduleMovie = data => {
  return async dispatch => {
    try {
      dispatch(addMovieBegin());
      let response = await DataService.post(`${'scheduledMovies'}`, data);
      if(response.data.success){
        {response.data.message?
          message.success({ content:  response.data.message, duration: 2 })
        :message.success({ content:  'Record Added Successfully', duration: 2 })}
        return dispatch(addMovieSuccess(response.data.data));
      }else{
        {response.data.message?
          message.error({ content:  response.data.message, duration: 2 })
        :message.success({ content:  'Something went wrong!', duration: 2 })}
        return dispatch(addMovieErr(response.data.message?response.data.message:'Something went wrong!'));
      }
      
    } catch (err) { dispatch(addMovieErr(err));
    }
  };
};

const updateScheduleMovie = data => {
  return async dispatch => {
    try {
      dispatch(movieUpdateBegin());
      let response = await DataService.put(`${'scheduledMovies/'}${data._id}`, data);
      if(response.data.success){
        {response.data.message?
          message.success({ content:  response.data.message, duration: 2 })
        :message.success({ content:  'Record Added Successfully', duration: 2 })}
        return dispatch(movieUpdateSuccess(response.data.data));
      }else{
        {response.data.message?
          message.error({ content:  response.data.message, duration: 2 })
        :message.success({ content:  'Something went wrong!', duration: 2 })}
        return dispatch(movieUpdateErr(response.data.message?response.data.message:'Something went wrong!'));
      }
      
    } catch (err) { dispatch(movieUpdateErr(err));
    }
  };
};

const removeScheduleMovie = data => {
  return async dispatch => {
    try {
      dispatch(movieUpdateBegin());
      let response = await DataService.delete(`${'scheduledMovies/'}${data._id}`);
      if(response.data.success){
        {response.data.message?
          message.success({ content:  response.data.message, duration: 2 })
        :message.success({ content:  'Record removeed Successfully', duration: 2 })}
        return dispatch(movieUpdateSuccess(response.data.data));
      }else{
        {response.data.message?
          message.error({ content:  response.data.message, duration: 2 })
        :message.success({ content:  'Something went wrong!', duration: 2 })}
        return dispatch(movieUpdateErr(response.data.message?response.data.message:'Something went wrong!'));
      }
    } catch (err) { dispatch(movieUpdateErr(err));
    }
  };
};

const getScheduleMovies = (data) => {
  return async dispatch => {
    try {
      dispatch(moviesBegin());
      let response;
      response = await DataService.get(`scheduledMovies`);
      if(response.data.success){
        // {response.data.message && response.data.message.title?
        //   message.success({ content:  response.data.message.title, duration: 2 })
        // :null}
        // {response.data.message && response.data.message.message?
        //   message.success({ content:  response.data.message.message, duration: 2 })
        // :null}
        return dispatch(moviesSuccess(response.data.data));
      }else{
        {response.data.message?
          message.error({ content:  response.data.message, duration: 2 })
        :message.success({ content:  'Something went wrong!', duration: 2 })}
        // {response.data.message && response.data.message.title?
        //   message.error({ content:  response.data.message.title, duration: 2 })
        // :null}
        // {response.data.message && response.data.message.message?
        //   message.error({ content:  response.data.message.message, duration: 2 })
        // :null}
        return dispatch(moviesErr(response.data.message));
      }
      
    } catch (err) { dispatch(moviesErr(err));
    }
  };
};

const getScheduleMovieById = (data) => {
  return async dispatch => {
    try {
      dispatch(moviesBegin());
      let response;
      response = await DataService.get(`scheduledMovies/${data}`);
      if(response.data.success){
        // {response.data.message && response.data.message.title?
        //   message.success({ content:  response.data.message.title, duration: 2 })
        // :null}
        // {response.data.message && response.data.message.message?
        //   message.success({ content:  response.data.message.message, duration: 2 })
        // :null}
        return dispatch(moviesSuccess(response.data.data));
      }else{
        {response.data.message?
          message.error({ content:  response.data.message, duration: 2 })
        :message.success({ content:  'Something went wrong!', duration: 2 })}
        // {response.data.message && response.data.message.title?
        //   message.error({ content:  response.data.message.title, duration: 2 })
        // :null}
        // {response.data.message && response.data.message.message?
        //   message.error({ content:  response.data.message.message, duration: 2 })
        // :null}
        return dispatch(moviesErr(response.data.message));
      }
      
    } catch (err) { dispatch(moviesErr(err));
    }
  };
};

const getMoviesInvitation = (data) => {
  return async dispatch => {
    try {
      dispatch(moviesBegin());
      let response;
      response = await DataService.get(`scheduledMovies/invites/movies`);
      if(response.data.success){
        // {response.data.message && response.data.message.title?
        //   message.success({ content:  response.data.message.title, duration: 2 })
        // :null}
        // {response.data.message && response.data.message.message?
        //   message.success({ content:  response.data.message.message, duration: 2 })
        // :null}
        return dispatch(moviesSuccess(response.data.data));
      }else{
        {response.data.message?
          message.error({ content:  response.data.message, duration: 2 })
        :message.success({ content:  'Something went wrong!', duration: 2 })}
        // {response.data.message && response.data.message.title?
        //   message.error({ content:  response.data.message.title, duration: 2 })
        // :null}
        // {response.data.message && response.data.message.message?
        //   message.error({ content:  response.data.message.message, duration: 2 })
        // :null}
        return dispatch(moviesErr(response.data.message));
      }
      
    } catch (err) { dispatch(moviesErr(err));
    }
  };
};

const getMoviesInvitationById = (data) => {
  return async dispatch => {
    try {
      dispatch(moviesBegin());
      let response;
      response = await DataService.get(`scheduledMovies/${data}`);
      if(response.data.success){
        // {response.data.message && response.data.message.title?
        //   message.success({ content:  response.data.message.title, duration: 2 })
        // :null}
        // {response.data.message && response.data.message.message?
        //   message.success({ content:  response.data.message.message, duration: 2 })
        // :null}
        return dispatch(moviesSuccess(response.data.data));
      }else{
        {response.data.message?
          message.error({ content:  response.data.message, duration: 2 })
        :message.success({ content:  'Something went wrong!', duration: 2 })}
        // {response.data.message && response.data.message.title?
        //   message.error({ content:  response.data.message.title, duration: 2 })
        // :null}
        // {response.data.message && response.data.message.message?
        //   message.error({ content:  response.data.message.message, duration: 2 })
        // :null}
        return dispatch(moviesErr(response.data.message));
      }
      
    } catch (err) { dispatch(moviesErr(err));
    }
  };
};

const getViewingHabitsReport = (data) => {
  return async dispatch => {
    try {
      dispatch(moviesBegin());
      let response;
      response = await DataService.get(`reports/viewingHabitsReports`);
      if(response.data.success){
        // {response.data.message && response.data.message.title?
        //   message.success({ content:  response.data.message.title, duration: 2 })
        // :null}
        // {response.data.message && response.data.message.message?
        //   message.success({ content:  response.data.message.message, duration: 2 })
        // :null}
        return dispatch(moviesSuccess(response.data.data));
      }else{
        {response.data.message?
          message.error({ content:  response.data.message, duration: 2 })
        :message.success({ content:  'Something went wrong!', duration: 2 })}
        // {response.data.message && response.data.message.title?
        //   message.error({ content:  response.data.message.title, duration: 2 })
        // :null}
        // {response.data.message && response.data.message.message?
        //   message.error({ content:  response.data.message.message, duration: 2 })
        // :null}
        return dispatch(moviesErr(response.data.message));
      }
      
    } catch (err) { dispatch(moviesErr(err));
    }
  };
};




export { addSingleMovie, getMovies, getMovieData, addWatchlist, addInWatched, getScheduleMovieById, getMoviesInvitationById, removeWatchlist, updateMovie, setScheduleMovie, getScheduleMovies, getViewingHabitsReport, getMoviesInvitation, updateScheduleMovie, removeScheduleMovie };
