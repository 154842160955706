import actions from './actions';

const {
  SCHADULEPOPUPSUCCESS,

} = actions;

const initialStateList = {
  data: '',
  loading: false,
  error: null,
};

const SchadulePopupReducer = (state = initialStateList, action) => {
  const { type, data, err } = action;
  switch (type) {
    case SCHADULEPOPUPSUCCESS:
      return {
        ...initialStateList,
        data,
        loading: false,
      };
    
    default:
      return state;
  }
};

export default SchadulePopupReducer;
