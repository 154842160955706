import Logo from '../../assets/images/logo.png';
import defaultUser from '../../assets/images/defaultUser.png';
import banner from '../../assets/images/banner.png';
import item from '../../assets/images/item.png';
import playIcon from '../../assets/images/play-circle.svg';
import googlePlay from '../../assets/images/googleplay.png';
import apple from '../../assets/images/apple.png';
import playVideo from '../../assets/images/play-circle.svg';
import imageBanner from '../../assets/images/image-banner.png';
import DownArrow from '../../assets/images/down-arrow.svg';
import UpArrow from '../../assets/images/up-arrow.svg';
import timeIcon from '../../assets/images/time-icon.svg';

const images = {
    Logo,
    banner,
    item,
    playIcon,
    googlePlay,
    apple,
    playVideo,
    imageBanner,
    timeIcon,
    UpArrow,
    DownArrow,
    defaultUser
}

export { images };