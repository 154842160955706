import actions from './actions';
import {DataService} from '../../config/dataService/dataService';
import { message } from 'antd';
import { API } from '../../config/api';
const {

  subscriptionBegin,
  subscriptionSuccess,
  subscriptionErr,

} = actions;


const addSubscription = (data) => {
  return async dispatch => {
    try {
      dispatch(subscriptionBegin());
      let response;
      response = await DataService.post(`subscription`, data);
      if(response.data.success){
        message.success({ content:  response.data.message, duration: 2 })
        // {response.data.message && response.data.message.title?
        //   message.success({ content:  response.data.message.title, duration: 2 })
        // :null}
        // {response.data.message && response.data.message.message?
        //   message.success({ content:  response.data.message.message, duration: 2 })
        // :null}
        return dispatch(subscriptionSuccess(response.data.data));
      }else{
        {response.data.message?
          message.error({ content:  response.data.message, duration: 2 })
        :message.error({ content:  'Something went wrong!', duration: 2 })}
        // {response.data.message && response.data.message.title?
        //   message.error({ content:  response.data.message.title, duration: 2 })
        // :null}
        // {response.data.message && response.data.message.message?
        //   message.error({ content:  response.data.message.message, duration: 2 })
        // :null}
        return dispatch(subscriptionErr(response.data.message));
      }
      
    } catch (err) { dispatch(subscriptionErr(err));
    }
  };
};

export { addSubscription };
