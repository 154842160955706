import React, { lazy, Suspense, useEffect, useState } from 'react';
import styled from "styled-components";
import { Spin } from 'antd';
import axios from 'axios';
import VastPlayer from 'vast-player-react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import socketIOClient from 'socket.io-client/dist/socket.io';
import { getMovieData, getScheduleMovieById } from '../../redux/movies/actionCreator';
import Player from './player';
import { getProfile } from '../../redux/user/actionCreator';
import config from '../../config/config';
import VideoPlayer from '../../components/AdsPlayer';
import { API } from '../../config/api';
const { theme, images, imagePath } = config;

  // const AbsolutelyPositionedPlayer = styled.div`
  //   width:768px;
  //   height:430px;
  //   // background: #000;
  //   position: absolute;
  //   top: 0;
  // `;
  // const RelativePositionWrapper = styled.div`
  //   position: relative;
  //   text-align: center;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  // `;
  const AbsolutelyPositionedPlayer = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background:#000;
    left:0;
    .video-js{
      width: 100% !important;
      height: 100% !important;
      position: absolute;
      padding: 0 !important;
      margin: 0 !important;
      .vjs-big-play-button{
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
      }
    }
    .vjs-control-bar{
      display:none !important;
    }
    iframe, video{
      width: 100%;
      height: 100%;
    }
  `;
  const RelativePositionWrapper = styled.div`
    text-align: center;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    align-items: center;
    position: fixed;
    z-index: 10000000000;
  `;

const videoOptions = {
  disableControls: true,
};

const socketIo = socketIOClient("https://app.cinemawalla.com", {
    transports: ['websocket'],
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax : 5000,
    reconnectionAttempts: Infinity
});


const PlayerPage = ({ match }) => {
  // const { userData } = useSelector(state => {
  //   return {
  //     userData: state.auth.data,
  //   };
  // });
  const { path, params  } = useRouteMatch();
  const dispatch = useDispatch();
  const [movie, setMovie] = useState(null);
  // const [token, setToken] = useState({});
  const [userInteract, setUserInteract] = useState(true);
  const [userData, setUserData] = useState(null);
  const [schedule, setschedule] = useState(null);
  const [xmlCode, setXmlCode] = useState(null);
  const [ads, setAds] = useState(false);
  const [loading, setLoading] = useState(true);
  // const [socket, setSocket] = useState(null);
  const [socketId, setSocketId] = useState(null);
  const history = useHistory();

  const fatchMovieData = async(id) => {
    setLoading(true);
    let returndata = await dispatch(getMovieData(id));
    if(returndata){
      if(returndata.type=="MOVIES_SUCCESS"){
        setMovie(returndata.data);
        // fatchXMLData(returndata.data, userDetails);
        setLoading(false);
      }else if(returndata.type=="MOVIES_ERR"){
        setLoading(false);
      }
    }else{
      setLoading(false);
    }
  }

  useEffect(() => {
    if(socketIo && socketIo.id){
      const SocketId = socketIo.id;
      setSocketId(SocketId);   
    }
  }, []);

  const fatchScheduleData = async(id) => {
    setLoading(true);
    let returndata = await dispatch(getScheduleMovieById(id));
    if(returndata){
      if(returndata.type=="MOVIES_SUCCESS"){
        setschedule(returndata.data);
        setLoading(false);
      }else if(returndata.type=="MOVIES_ERR"){
        setLoading(false);
      }
    }else{
      setLoading(false);
    }
  }

  const fatchUserData = async(userId, movieId, scheduleId) => {
    setLoading(true);
    let returndata = await dispatch(getProfile({id: userId}));
    if(returndata){
      if(returndata.type=="USER_SUCCESS"){
        // let data =[];
        setUserData(returndata.data);
        fatchMovieData(movieId);
        fatchScheduleData(scheduleId);
      }else if(returndata.type=="USER_ERR"){
        
      }
    }
  }

  const fatchXMLData = (data, userDetails) => {
    const age = Number(new Date().getFullYear()) - Number(userDetails.year_of_birth);
    // https://adservice.cinevisualis.com/fc.php?script=rmVideo&zoneid=7&format=vast_pod&filmtype={filmtype}&userid={userid}&&age={age}&loc="+window.location.hostname+"
    // https://demo.reviveadservermod.com/advanced_vast/fc.php?script=rmVideo&zoneid=48&format=vast4&loc=
  
    axios.get(`https://adservice.cinevisualis.com/fc.php?script=rmVideo&zoneid=7&format=vast_pod&filmtype=${data.type}&userid=${userDetails.id}&&age=${age}&loc=${window.origin}`,{ crossDomain: true }).then((xml) => {
      console.log(xml);
      setAds(true);
      setXmlCode(xml.data);
      // ReactPlayer.addCustomPlayer(VastPlayer);

      // setPlaying(true);
    })
  }

    
  const alertFinished = (data) => {
    setAds(false);
    setXmlCode(null);
  }

  useEffect(() => {
    // console.log('params.id', params);
    fatchUserData(params.userId, params.id, params.scheduleId);
  }, [params.userId, params.id, params.scheduleId]);

  useEffect(() => {
    var body = document.getElementById("root");
    body.classList.add("app-video");
    // setToken({
    //   'Authorization': `Bearer ${params.token}`,
    // });
  }, []);

  useEffect( () => () => console.log("unmount"), [] );
  

  return (
    movie && userData?
      <RelativePositionWrapper>
        <AbsolutelyPositionedPlayer>
          <VideoPlayer userData={userData} movie={movie} scheduleId={params.scheduleId} schedule={schedule} appPlayer={true} socketIo={socketIo}  />
          {/* {
            xmlCode && ads?
            userInteract?
              <div className="posterimage" >
                <img src={ `${imagePath + movie.poster}` } style={{width:'100%', height: 'auto', maxHeight: '100%' }} />
                <img className="play-icon" onClick={()=> setUserInteract(false) } alt="paly" src={images.playIcon} />
              </div>
            :<VastPlayer
              vastXml={xmlCode}
              height={window.innerHeight}
              width={window.innerWidth}
              videoOptions={videoOptions}
              onEnded={alertFinished}
            />
            : <Player userData={userData} token={token} movie={movie} scheduleId={params.scheduleId} />
          } */}

        </AbsolutelyPositionedPlayer>
          {/* <AbsolutelyPositionedPlayer /> */}
      </RelativePositionWrapper>
    //
    :<div className='spin'><Spin /></div>
  );
};

export default PlayerPage;
