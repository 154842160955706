const actions = {
  SCHADULEPOPUPSUCCESS: 'SCHADULEPOPUPSUCCESS',

  schadulePopupSuccess: data => {
    return {
      type: actions.SCHADULEPOPUPSUCCESS,
      data,
    };
  },

};

export default actions;
