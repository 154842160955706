import firebase from "firebase";
import "firebase/auth";
// import { getMessaging } from "firebase/messaging";
// import { initializeApp } from 'firebase/app';
import "firebase/database";
const initializedFirebaseApp = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
});

// firebase.auth().signInAnonymously()
//   .then(() => {
//     console.log('rehrrthrhhg');
//     // Signed in..
//   })
//   .catch((error) => {
//     // var errorCode = error.code;
//     // var errorMessage = error.message;
//     // // ...
// });


const checkAuth = () => {
  firebase.auth().signInWithEmailAndPassword('sadmin@cinemawalla.com', 'Khamosh_2022')
  .then((userCredential) => {
    // Signed in
    var user = userCredential.user;
   
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        var uid = user.uid;
        // console.log('user', uid, user);
        // ...
      } else {
        // User is signed out
        // ...
      }
    });
    // ...
  })
  .catch((error) => {
    var errorCode = error.code;
    var errorMessage = error.message;
    // console.log(errorCode, errorMessage);
  });
  // firebase.auth().signInAnonymously()
  // .then(() => {
  //   console.log('Auth Success');
  //   // Signed in..
  // })
  // .catch((error) => {
  //   var errorCode = error.code;
  //   var errorMessage = error.message;
  //   console.log(errorCode, errorMessage);
  // });
}


const removeAuth = () => {
  if(firebase){
    var user = firebase.auth().currentUser;
    // console.log('user', user);
    if (user) {
      firebase.auth().signOut().then(() => {
        // Sign-out successful.
      }).catch((error) => {
        // An error happened.
      });

    } else {
      // No user is signed in.
    }
    
  } 
}

// const auth initializedFirebaseApp.auth().getInstance().signInAnonymously();

// console.log("firebase.messaging.isSupported())", firebase.messaging.isSupported())
const messaging = null //getMessaging();

const db = firebase.database();
// console.log("messaging, db", messaging, db)
export { messaging, db, checkAuth, removeAuth };
