import actions from './actions';
import {DataService} from '../../config/dataService/dataService';
import { message } from 'antd';
const {
  notificationBegin,
  notificationSuccess,
  notificationErr,

  unreadNotificationBegin,
  unreadNotificationSuccess,
  unreadNotificationErr,

  sendNotificationBegin,
  sendNotificationSuccess,
  sendNotificationErr,

} = actions;


const sendNotification = data => {
  return async dispatch => {
    try {
      dispatch(sendNotificationBegin());
      let response = await DataService.post(`${'notification'}`, data);
      if(response.data.success){
        {response.data.message?
          message.success({ content:  response.data.message, duration: 2 })
        :message.success({ content:  'Record Added Successfully', duration: 2 })}
        return dispatch(sendNotificationSuccess(response.data.data));
      }else{
        {response.data.message?
          message.error({ content:  response.data.message, duration: 2 })
        :message.success({ content:  'Something went wrong!', duration: 2 })}
        return dispatch(sendNotificationErr(response.data.message?response.data.message:'Something went wrong!'));
      }
      
    } catch (err) { dispatch(sendNotificationErr(err));
    }
  };
};

const getUnreadtNotification = data => {
  return async dispatch => {
    try {
      dispatch(unreadNotificationBegin());
      let response = await DataService.post(`notification/unread`);
      if(response.data.success){
        return dispatch(unreadNotificationSuccess(response.data.data));
      }else{
        {response.data.message?
          message.error({ content:  response.data.message, duration: 2 })
        :message.error({ content:  'Something went wrong!', duration: 2 })}
        return dispatch(unreadNotificationErr(response.data.message));
      }
      
    } catch (err) {       dispatch(unreadNotificationErr(err));
    }
  };
};

const getNotifications = data => {
  return async dispatch => {
    try {
      dispatch(notificationBegin());
      let response = await DataService.get(`notification`);
      if(response.data.success){
        return dispatch(notificationSuccess(response.data.data));
      }else{
        {response.data.message?
          message.error({ content:  response.data.message, duration: 2 })
        :message.error({ content:  'Something went wrong!', duration: 2 })}
        return dispatch(notificationErr(response.data.message));
      }
      
    } catch (err) {       dispatch(notificationErr(err));
    }
  };
};





export { getUnreadtNotification, getNotifications, sendNotification };
