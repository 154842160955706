import actions from './actions';

const {
  NOTIFICATION_BEGIN,
  NOTIFICATION_SUCCESS,
  NOTIFICATION_ERR,

  UNREADNOTIFICATION_BEGIN,
  UNREADNOTIFICATION_SUCCESS,
  UNREADNOTIFICATION_ERR,

} = actions;

const initialStateList = {
  data: [],
  loading: false,
  error: null,
};

const NotificationReducer = (state = initialStateList, action) => {
  const { type, data, err } = action;
  switch (type) {
    case NOTIFICATION_BEGIN:
      return {
        ...initialStateList,
        loading: true,
      };
    case NOTIFICATION_SUCCESS:
      return {
        ...initialStateList,
        data,
        loading: false,
      };
    case NOTIFICATION_ERR:
      return {
        ...initialStateList,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

const UnreadNotificationReducer = (state = initialStateList, action) => {
  const { type, data, err } = action;
  switch (type) {
    case UNREADNOTIFICATION_BEGIN:
      return {
        ...initialStateList,
        loading: true,
      };
    case UNREADNOTIFICATION_SUCCESS:
      return {
        ...initialStateList,
        data,
        loading: false,
      };
    case UNREADNOTIFICATION_ERR:
      return {
        ...initialStateList,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

export { NotificationReducer, UnreadNotificationReducer};
