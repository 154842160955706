const actions = {
 
  SEARCH_BEGIN: 'SEARCH_BEGIN',
  SEARCH_SUCCESS: 'SEARCH_SUCCESS',
  SEARCH_ERR: 'SEARCH_ERR',

  FILTERSUCCESS: 'FILTERSUCCESS', 
  SEARCHTEXTSUCCESS: 'SEARCHTEXTSUCCESS',

  searchBegin: () => {
    return {
      type: actions.SEARCH_BEGIN,
    };
  },

  searchSuccess: data => {
    return {
      type: actions.SEARCH_SUCCESS,
      data,
    };
  },

  searchErr: err => {
    return {
      type: actions.SEARCH_ERR,
      err,
    };
  },

  filterSuccess: data => {
    return {
      type: actions.FILTERSUCCESS,
      data,
    };
  },

  searchTextSuccess: data => {
    return {
      type: actions.SEARCHTEXTSUCCESS,
      data,
    };
  },

};

export default actions;
