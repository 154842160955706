import React from 'react';
import socketio from "socket.io-client";
const URL = 'https://app.cinemawalla.com';

export const socket = socketio.connect(URL, {
    transports: ['websocket'],
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax : 5000,
    reconnectionAttempts: Infinity
});
export const SocketContext = React.createContext();