import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import 'https://cdnjs.cloudflare.com/ajax/libs/video.js/7.2.3/video-js.min.css';
// import 'https://cdnjs.cloudflare.com/ajax/libs/video.js/7.2.3/video.min.js';
// import 'https://cdnjs.cloudflare.com/ajax/libs/videojs-contrib-ads/6.6.4/videojs.ads.css';
// import 'https://cdnjs.cloudflare.com/ajax/libs/videojs-contrib-ads/6.6.4/videojs.ads.min.js';
// import 'https://raw.githubusercontent.com/philipwatson/videojsx-vast-plugin/master/dist/videojsx.vast.css';
// import 'https://raw.githubusercontent.com/philipwatson/videojsx-vast-plugin/master/dist/videojsx.vast.js';
// import 'https://raw.githubusercontent.com/philipwatson/videojsx-vast-plugin/master/dist/player.js';

import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  // <React.StrictMode>
    <App />,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
