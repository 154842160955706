const actions = {
    ADD_MOVIE_BEGIN: 'ADD_MOVIE_BEGIN',
    ADD_MOVIE_SUCCESS: 'ADD_MOVIE_SUCCESS',
    ADD_MOVIE_ERR: 'ADD_MOVIE_ERR',
  
    MOVIES_BEGIN: 'MOVIES_BEGIN',
    MOVIES_SUCCESS: 'MOVIES_SUCCESS',
    MOVIES_ERR: 'MOVIES_ERR',
  
    MOVIES_ALL_BEGIN: 'MOVIES_ALL_BEGIN',
    MOVIES_ALL_SUCCESS: 'MOVIES_ALL_SUCCESS',
    MOVIES_ALL_ERR: 'MOVIES_ALL_ERR',
  
    MOVIES_BULK_BEGIN: 'MOVIES_BULK_BEGIN',
    MOVIES_BULK_SUCCESS: 'MOVIES_BULK_SUCCESS',
    MOVIES_BULK_ERR: 'MOVIES_BULK_ERR',
  
    MOVIE_UPDATE_BEGIN: 'MOVIE_UPDATE_BEGIN',
    MOVIE_UPDATE_SUCCESS: 'MOVIE_UPDATE_SUCCESS',
    MOVIE_UPDATE_ERR: 'MOVIE_UPDATE_ERR',
    
    MOVIE_DELETE_BEGIN: 'MOVIE_DELETE_BEGIN',
    MOVIE_DELETE_SUCCESS: 'MOVIE_DELETE_SUCCESS',
    MOVIE_DELETE_ERR: 'MOVIE_DELETE_ERR',
  
  
    addMovieBegin: () => {
      return {
        type: actions.ADD_MOVIE_BEGIN,
      };
    },
  
    addMovieSuccess: data => {
      return {
        type: actions.ADD_MOVIE_SUCCESS,
        data,
      };
    },
  
    addMovieErr: err => {
      return {
        type: actions.ADD_MOVIE_ERR,
        err,
      };
    },
  
    moviesBegin: () => {
      return {
        type: actions.MOVIES_BEGIN,
      };
    },
  
    moviesSuccess: data => {
      return {
        type: actions.MOVIES_SUCCESS,
        data,
      };
    },
  
    moviesErr: err => {
      return {
        type: actions.MOVIES_ERR,
        err,
      };
    },
  
    allMoviesBegin: () => {
      return {
        type: actions.MOVIES_ALL_BEGIN,
      };
    },
  
    allMoviesSuccess: data => {
      return {
        type: actions.MOVIES_ALL_SUCCESS,
        data,
      };
    },
  
    allMoviesErr: err => {
      return {
        type: actions.MOVIES_ALL_ERR,
        err,
      };
    },
  
    moviesBulkBegin: () => {
      return {
        type: actions.MOVIES_BULK_BEGIN,
      };
    },
  
    moviesBulkSuccess: data => {
      return {
        type: actions.MOVIES_BULK_SUCCESS,
        data,
      };
    },
  
    moviesBulkErr: err => {
      return {
        type: actions.MOVIES_BULK_ERR,
        err,
      };
    },
  
    movieUpdateBegin: () => {
      return {
        type: actions.MOVIE_UPDATE_BEGIN,
      };
    },
  
    movieUpdateSuccess: data => {
      return {
        type: actions.MOVIE_UPDATE_SUCCESS,
        data,
      };
    },
  
    movieUpdateErr: err => {
      return {
        type: actions.MOVIE_UPDATE_ERR,
        err,
      };
    },
  
    movieDeleteBegin: () => {
      return {
        type: actions.MOVIE_DELETE_BEGIN,
      };
    },
  
    movieDeleteSuccess: data => {
      return {
        type: actions.MOVIE_DELETE_SUCCESS,
        data,
      };
    },
  
    movieDeleteErr: err => {
      return {
        type: actions.MOVIE_DELETE_ERR,
        err,
      };
    },
  
  };
  
  export default actions;
  