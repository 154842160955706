const actions = {
  LOGIN_BEGIN: 'LOGIN_BEGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERR: 'LOGIN_ERR',
  LOGIN_VARIFY: 'LOGIN_VARIFY',

  SIGNUP_BEGIN: 'SIGNUP_BEGIN',
  SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
  SIGNUP_ERR: 'SIGNUP_ERR',

  FORGOT_SUCCESS:'FORGOT_SUCCESS',
  FORGOT_BEGIN:'FORGOT_BEGIN',
  FORGOT_ERR:'FORGOT_ERR',

  VERIFY_SUCCESS:'VERIFY_SUCCESS',
  VERIFY_BEGIN:'VERIFY_BEGIN',
  VERIFY_ERR:'VERIFY_ERR',

  RECOVER_SUCCESS:'RECOVER_SUCCESS',
  RECOVER_BEGIN:'RECOVER_BEGIN',
  RECOVER_ERR:'RECOVER_ERR',

  LOGOUT_BEGIN: 'LOGOUT_BEGIN',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_ERR: 'LOGOUT_ERR',
  

  loginBegin: () => {
    return {
      type: actions.LOGIN_BEGIN,
    };
  },

  loginSuccess: data => {
    return {
      type: actions.LOGIN_SUCCESS,
      data,
    };
  },

  loginVarify: data => {
    return {
      type: actions.LOGIN_VARIFY,
      data,
    };
  },

  loginErr: err => {
    return {
      type: actions.LOGIN_ERR,
      err,
    };
  },

  forgotBegin: () => {
    return {
      type: actions.FORGOT_BEGIN,
    };
  },

  forgotSuccess: data => {
    return {
      type: actions.FORGOT_SUCCESS,
      data,
    };
  },

  forgotErr: err => {
    return {
      type: actions.FORGOT_ERR,
      err,
    };
  },

  verifyBegin: () => {
    return {
      type: actions.VERIFY_BEGIN,
    };
  },

  verifySuccess: data => {
    return {
      type: actions.VERIFY_SUCCESS,
      data,
    };
  },

  verifyErr: err => {
    return {
      type: actions.VERIFY_ERR,
      err,
    };
  },

  recoverBegin: () => {
    return {
      type: actions.RECOVER_BEGIN,
    };
  },

  recoverSuccess: data => {
    return {
      type: actions.RECOVER_SUCCESS,
      data,
    };
  },

  recoverErr: err => {
    return {
      type: actions.RECOVER_ERR,
      err,
    };
  },

  signupBegin: () => {
    return {
      type: actions.SIGNUP_BEGIN,
    };
  },

  signupSuccess: data => {
    return {
      type: actions.SIGNUP_SUCCESS,
      data,
    };
  },

  signupErr: err => {
    return {
      type: actions.SIGNUP_ERR,
      err,
    };
  },




  logoutBegin: () => {
    return {
      type: actions.LOGOUT_BEGIN,
    };
  },

  logoutSuccess: data => {
    return {
      type: actions.LOGOUT_SUCCESS,
      data,
    };
  },

  logoutErr: err => {
    return {
      type: actions.LOGOUT_ERR,
      err,
    };
  },
};

export default actions;
