import axios from 'axios';
import { getItem, getCookies } from '../../utility/localStorageControl';
console.log(window.location.protocol);
const API_ENDPOINT = window.location.protocol === 'http:' ? process.env.REACT_APP_API_ENDPOINT : process.env.REACT_APP_API_ENDPOINT.replace('http:', 'https:');

// const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT_LOCAL;

const BASR_URL = window.location.protocol === 'http:' ? process.env.REACT_APP_BASE_URL : process.env.REACT_APP_BASE_URL.replace('http:', 'https:');

// const BASR_URL = process.env.REACT_APP_BASE_URL_LOCAL;

const authHeader = () => ({
  'Authorization': `Bearer ${window.atob(localStorage.getItem('access_token'))}`,
});

const client = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    'Authorization': `Bearer ${window.atob(localStorage.getItem('access_token'))}`,
    'Content-Type': 'application/json',
  },
});

class DataService {

  static getBaseUrl() {
    return BASR_URL;
  }

  static getApiUrl() {
    return API_ENDPOINT;
  }

  static getAccessToken() {
    return {
      'Authorization': `Bearer ${window.atob(localStorage.getItem('access_token'))}`,
      'Content-Type': false,
      "mimeType": "multipart/form-data",
    };
  }

  static get(path = '', optionalHeader = {}) {
    return client({
      method: 'GET',
      url: path,
      headers: { ...authHeader(), ...optionalHeader },
    });
  }

  static post(path = '', data = {}, optionalHeader = {}) {
    return client({
      method: 'POST',
      url: path,
      data,
      headers: { ...authHeader(), ...optionalHeader },
    });
  }

  static patch(path = '', data = {}) {
    return client({
      method: 'PATCH',
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }

  static delete(path = '', data = {}) {
    return client({
      method: 'DELETE',
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }

  static put(path = '', data = {}, optionalHeader = {}) {
    return client({
      method: 'PUT',
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader(), ...optionalHeader },
    });
  }
}

/**
 * axios interceptors runs before and after a request, letting the developer modify req,req more
 * For more details on axios interceptor see https://github.com/axios/axios#interceptors
 */
client.interceptors.request.use(config => {
  // do something before executing the request
  // For example tag along the bearer access token to request header or set a cookie
  const requestConfig = config;
  //const { headers } = config;
  //requestConfig.headers = { ...headers, Authorization: `Bearer ${getCookies('token')}` };

  return requestConfig;
});

client.interceptors.response.use(
  response => {
    return {data:{success:true, data:response.data.data, message:response.data.message}}
  },
  error => {
    /**
     * Do something in case the response returns an error code [3**, 4**, 5**] etc
     * For example, on token expiration retrieve a new access token, retry a failed request etc
     */
    const { response } = error;
    const originalRequest = error.config;
    if (response) {
      if (response.status === 500) {
        // do something here
        return {data:{success:false, message:'Something went wrong!'}};
      }if (response.status === 404) {
        return {data:{success:false, message:'Page not found'}};
      } else {
        if(response.data.errors && response.data.errors.length>0){
          return {data:{success:false, message:response.data.errors.join(', ')}};
        }else{
          return {data:{success:false, message:response.data.message}};
        }
        
      }
    }
    return Promise.reject(error);
  },
);
export { DataService };
