import React, { lazy, Suspense } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, useRouteMatch, Redirect, useHistory  } from 'react-router-dom';
import withLayout from '../layout/withLayout';
import { Spin, Result, Button } from 'antd';
import AppPlayer from '../container/player/appplayer';
import { connectSocket } from '../redux/messages/actionCreator';
import { useEffect } from 'react';

const Home = lazy(() => import('../container/home'));
const Search = lazy(() => import('../container/search'));
const Registration = lazy(() => import('../container/registration'));
const Favourite = lazy(() => import('../container/favourite'));
const FAQ = lazy(() => import('../container/faq'));
const Support = lazy(() => import('../container/support'));
const Scheduled = lazy(() => import('../container/scheduled'));
const Profile = lazy(() => import('../container/profile'));
const ProfileEdit = lazy(() => import('../container/profile/edit'));
const ChangePasswordPage = lazy(() => import('../container/profile/changepassword'));
const Player = lazy(() => import('../container/player'));
const NewPlayer = lazy(() => import('../container/player/new'));
const Settings = lazy(() => import('../container/settings'));
const Details = lazy(() => import('../container/details'));
const AddMovie = lazy(() => import('../container/addmovie'));
const Reports = lazy(() => import('../container/reports'));
const Friends = lazy(() => import('../container/friends'));
const Movies = lazy(() => import('../container/movies'));
const Messages = lazy(() => import('../container/messages'));
const Reviews = lazy(() => import('../container/reviews'));
const About = lazy(() => import('../container/about'));
const Terms = lazy(() => import('../container/terms'));
const Privacy = lazy(() => import('../container/privacy'));
const TimeLineScreen = lazy(() => import('../container/timeline'));
const UCG = lazy(() => import('../container/ugc'));
const Notification = lazy(() => import('../container/notification'));
// const AppPlayer = lazy(() => import('../container/player/appplayer'));
const Routes = () => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const userData = useSelector(state => state.auth.data);
  // console.log(userData);
  const history = useHistory();
  //const path = '/';
  // 
  const PageNotFound = () => {
    return  <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={<Button onClick={()=> history.push('/')} style={{ background: '#ec6a40', border: 0}} type="primary">Back Home</Button>}
    />
  }  

  return (
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Switch>
          <Route exact path={`${path}`} component={Home} />
          <Route exact path={`${path}search`} component={Search} />
          <Route exact path={`${path}registration/:type?`} component={userData && userData.id?Home:Registration} />
          <Route exact path={`${path}forgot`} component={userData && userData.id?Home:Registration} />
          <Route exact path={`${path}verify`} component={userData && userData.id?Home:Registration} />
          <Route exact path={`${path}recoverypassword/:id`} component={userData && userData.id?Home:Registration} />
          <Route exact path={`${path}suspendaccount/:id`} component={userData && userData.id?Home:Registration} />
          <Route exact path={`${path}deleteaccount/:id`} component={userData && userData.id?Home:Registration} />
          <Route exact path={`${path}recoverypin/:id`} component={userData && userData.id?Home:Registration} />
          <Route exact path={`${path}verifyotp/:email`} component={userData && userData.id?Home:Registration} />
          {/* <Route exact path={`${path}favourite`} component={userData && userData.id?Favourite:Home} /> */}
          <Route exact path={`${path}privacy-policy`} component={Privacy} />
          <Route exact path={`${path}terms-service`} component={Terms} />
          <Route exact path={`${path}ugc`} component={UCG} />
          <Route exact path={`${path}faq`} component={FAQ} />
          <Route exact path={`${path}about`} component={About} />
          <Route exact path={`${path}support/:ticketId?`} component={Support} />
          <Route exact path={`${path}friends`} component={Friends} />
          <Route exact path={`${path}details/:id/:scheduleId?`} component={Details} />
          <Route exact path={`${path}movies/:type?`} component={Movies} />
          <Route exact path={`${path}newplayer/:id/:scheduleId?`} component={NewPlayer} />
          <Route path={`${path}appplayer/:userId/:id/:scheduleId?`} component={AppPlayer} />
          <Route exact path={`${path}notification`} component={Notification} />
          <Route exact path={`${path}messages/:id?`} component={userData && userData.id?Messages:Home} />
          <Route exact path={`${path}settings`} component={userData && userData.id?Settings:Home} />   
          <Route exact path={`${path}reviews/:id`} component={Reviews} />          
          <Route exact path={`${path}addmovie`} component={userData && userData.id?AddMovie:Home} />
          <Route exact path={`${path}scheduled`} component={userData && userData.id?Scheduled:Home } />
          <Route exact path={`${path}profile`} component={userData && userData.id?Profile:Home } />
          <Route exact path={`${path}timeline`} component={userData && userData.id?TimeLineScreen:Home } />
          <Route exact path={`${path}reports`} component={userData && userData.id?Reports:Home } />
          <Route exact path={`${path}profile/edit`} component={userData && userData.id?ProfileEdit:Home} />
          <Route exact path={`${path}profile/changepassword`} component={userData && userData.id?ChangePasswordPage:Home} />
          <Route exact path={`${path}player/:id/:scheduleId?`} component={userData && userData.id?Player:Home } />
          <Route path={`*`} component={PageNotFound} />
        </Switch>
        
        {/* {
          userData && userData.id?
          <Switch>
            
          </Switch>
          :<Redirect to={`${path}`} />
        } */}
         {/* <Route path='*' component={PageNotFound} /> */}
      </Suspense>
  );
};

export default withLayout(Routes);
