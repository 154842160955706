import actions from './actions';
import {DataService} from '../../config/dataService/dataService';
import { message } from 'antd';
import { API } from '../../config/api';
const {

  userBegin,
  userSuccess,
  userErr,

  userUpdateBegin,
  userUpdateSuccess,
  userUpdateErr,

} = actions;



const getProfile = (data) => {
  return async dispatch => {
    try {
      dispatch(userBegin());
      let response;
      response = await DataService.get(`user/${data.id}`);
      if(response.data.success){
        // {response.data.message && response.data.message.title?
        //   message.success({ content:  response.data.message.title, duration: 2 })
        // :null}
        // {response.data.message && response.data.message.message?
        //   message.success({ content:  response.data.message.message, duration: 2 })
        // :null}
        return dispatch(userSuccess(response.data.data));
      }else{
        {response.data.message?
          message.error({ content:  response.data.message, duration: 2 })
        :message.success({ content:  'Something went wrong!', duration: 2 })}
        // {response.data.message && response.data.message.title?
        //   message.error({ content:  response.data.message.title, duration: 2 })
        // :null}
        // {response.data.message && response.data.message.message?
        //   message.error({ content:  response.data.message.message, duration: 2 })
        // :null}
        return dispatch(userErr(response.data.message));
      }
      
    } catch (err) { dispatch(userErr(err));
    }
  };
};


const getUserById = (data) => {
  return async dispatch => {
    try {
      dispatch(userBegin());
      let response;
      response = await DataService.get(`user/${data.id}`);
      if(response.data.success){
        return dispatch(userSuccess(response.data.success));
      }else{
        {response.data.message?
          message.error({ content:  response.data.message, duration: 2 })
        :message.success({ content:  'Something went wrong!', duration: 2 })}
        return dispatch(userErr(response.data.message));
      }
      
    } catch (err) { dispatch(userErr(err));
    }
  };
};

const updateProfile = (data) => {
  return async dispatch => {
    try {
      dispatch(userUpdateBegin());
      let response;
      response = await DataService.put(`user/${data.id}`, data);
      if(response.data.success){
        message.success({ content:  response.data.message, duration: 2 })
        // {response.data.message && response.data.message.title?
        //   message.success({ content:  response.data.message.title, duration: 2 })
        // :null}
        // {response.data.message && response.data.message.message?
        //   message.success({ content:  response.data.message.message, duration: 2 })
        // :null}
        await localStorage.setItem('cinemawallaWebUser', window.btoa(JSON.stringify(response.data.data)));
        // dispatch(loginSuccess(response.data.data));
        return dispatch(userUpdateSuccess(response.data.data));
        
      }else{
        {response.data.message?
          message.error({ content:  response.data.message, duration: 2 })
        :message.success({ content:  'Something went wrong!', duration: 2 })}
        // {response.data.message && response.data.message.title?
        //   message.error({ content:  response.data.message.title, duration: 2 })
        // :null}
        // {response.data.message && response.data.message.message?
        //   message.error({ content:  response.data.message.message, duration: 2 })
        // :null}
        return dispatch(userUpdateErr(response.data.message));
      }
      
    } catch (err) { dispatch(userUpdateErr(err));
    }
  };
};

const getFriends = (data) => {
  return async dispatch => {
    try {
      dispatch(userBegin());
      let response;
      response = await DataService.get(`user/friends/list`);
      if(response.data.success){
        // {response.data.message && response.data.message.title?
        //   message.success({ content:  response.data.message.title, duration: 2 })
        // :null}
        // {response.data.message && response.data.message.message?
        //   message.success({ content:  response.data.message.message, duration: 2 })
        // :null}
        return dispatch(userSuccess(response.data.data));
      }else{
        {response.data.message?
          message.error({ content:  response.data.message, duration: 2 })
        :message.success({ content:  'Something went wrong!', duration: 2 })}
        // {response.data.message && response.data.message.title?
        //   message.error({ content:  response.data.message.title, duration: 2 })
        // :null}
        // {response.data.message && response.data.message.message?
        //   message.error({ content:  response.data.message.message, duration: 2 })
        // :null}
        return dispatch(userErr(response.data.message));
      }
      
    } catch (err) { dispatch(userErr(err));
    }
  };
};

const getTimelines = (data) => {
  return async dispatch => {
    try {
      // {userId: userInfo.id}
      dispatch(userBegin());
      let response;
      response = await DataService.post(`timeline`, data);
      // response = await DataService.post(`timelinePagination`, data);
      if(response.data.success){
        // {response.data.message && response.data.message.title?
        //   message.success({ content:  response.data.message.title, duration: 2 })
        // :null}
        // {response.data.message && response.data.message.message?
        //   message.success({ content:  response.data.message.message, duration: 2 })
        // :null}
        return dispatch(userSuccess(response.data.data));
      }else{
        {response.data.message?
          message.error({ content:  response.data.message, duration: 2 })
        :message.success({ content:  'Something went wrong!', duration: 2 })}
        // {response.data.message && response.data.message.title?
        //   message.error({ content:  response.data.message.title, duration: 2 })
        // :null}
        // {response.data.message && response.data.message.message?
        //   message.error({ content:  response.data.message.message, duration: 2 })
        // :null}
        return dispatch(userErr(response.data.message));
      }
      
    } catch (err) { dispatch(userErr(err));
    }
  };
};

const addFriends = (data) => {
  return async dispatch => {
    try {
      dispatch(userUpdateBegin());
      let response;
      response = await DataService.post(`friend`, data);
      if(response.data.success){
        // {response.data.message && response.data.message.title?
        //   message.success({ content:  response.data.message.title, duration: 2 })
        // :null}
        // {response.data.message && response.data.message.message?
        //   message.success({ content:  response.data.message.message, duration: 2 })
        // :null}
        return dispatch(userUpdateSuccess(response.data.data));
      }else{
        {response.data.message?
          message.error({ content:  response.data.message, duration: 2 })
        :message.success({ content:  'Something went wrong!', duration: 2 })}
        // {response.data.message && response.data.message.title?
        //   message.error({ content:  response.data.message.title, duration: 2 })
        // :null}
        // {response.data.message && response.data.message.message?
        //   message.error({ content:  response.data.message.message, duration: 2 })
        // :null}
        return dispatch(userUpdateErr(response.data.message));
      }
      
    } catch (err) { userUpdateErr(userErr(err));
    }
  };
};


const suspendAccount = (data) => {
  return async dispatch => {
    try {
      dispatch(userUpdateBegin());
      let response;
      response = await DataService.post(`user/suspendaccount/user`, data);
      if(response.data.success){
        return dispatch(userUpdateSuccess(response.data.data));
      }else{
        {response.data.message?
          message.error({ content:  response.data.message, duration: 2 })
        :message.success({ content:  'Something went wrong!', duration: 2 })}
         return dispatch(userUpdateErr(response.data.message));
      }
      
    } catch (err) { userUpdateErr(userErr(err));
    }
  };
};


export { getProfile, getUserById, getTimelines, updateProfile, getFriends, addFriends, suspendAccount };
