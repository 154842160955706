import React, { useEffect, useState } from 'react';
// import { hot } from 'react-hot-loader/root';
import Cookies from 'js-cookie';
import { Provider, useSelector } from 'react-redux';
import { ConfigProvider } from 'antd';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import store from './redux/store';
import Routes from './routes';
import config from './config/config'
import { SocketContext, socket } from './socketProvider';

const { theme, themeColors } = config;

ConfigProvider.config({
  theme:{...themeColors}
});


const ProviderConfig = () => {
  // const {pathname} = useLocation();
  const { isLoggedIn } = useSelector(state => {
    return {
      isLoggedIn: state.auth.login,
    };
  });

  // const [isConnected, setIsConnected] = useState(socketIo.connected);
  // const [fooEvents, setFooEvents] = useState([]);

  // useEffect(() => {
  //   function onConnect() {
  //     console.log('Connected...');
  //     setIsConnected(true);
  //   }

  //   function onDisconnect() {
  //     setIsConnected(false);
  //   }

  //   // function onFooEvent(value) {
  //   //   console.log('value', value);
  //   //   setFooEvents(previous => [...previous, value]);
  //   // }

  //   socketIo.on('connect', onConnect);
  //   socketIo.on('disconnect', onDisconnect);
  //   // socketIo.on('foo', onFooEvent);

  //   return () => {
  //     socketIo.off('connect', onConnect);
  //     socketIo.off('disconnect', onDisconnect);
  //   };
  // }, []);

  // const cookieContainer = document.querySelector(".cookie-container");
  //     const agreeBtn = document.querySelector(".agree button");

  //     console.log(window.location);
  //     if(window.location.pathname == '/privacy-policy'){
  //       cookieContainer.classList.add("hide");
  //     }else{

  //     }

  //     function locationHashChanged( e ) {
  //         console.log( location.hash );
          
  //     }

  //     window.onhashchange = locationHashChanged;
  //     // setTimeout(() => {
  //     //   cookieContainer.classList.remove("hide");
  //     // }, 1000);

  //     // agreeBtn.addEventListener("click", () => {
  //     //   cookieContainer.classList.add("hide");
  //     // });

const [path, setPath] = useState(window.location.pathname);
  // useEffect(() => {
  //   console.log('sasasasas', window.location.pathname);
  //   setnewpath(window.location.pathname);
  // }, [pathname]);
 

  useEffect(() => {
    
    let unmounted = false;
    if (!unmounted) {
      console.log('path', path);
      setPath(window.location.pathname);
    }
    
    return () => (unmounted = true);
  }, [setPath]);
  return (
    <ConfigProvider direction={'ltr'} >
      <ThemeProvider theme={{ ...theme }}>
        {/* <SocketContext.Provider value={socketIoIo}> */}
        <Router basename={process.env.PUBLIC_URL}>
          {/* {isLoggedIn ? <ProtectedRoute path={'/'} component={Admin} /> :<Route path={'/'} component={Auth} /> } */}
          <Route path={'/'} component={Routes} />
          {isLoggedIn && (path === process.env.PUBLIC_URL || path === `${process.env.PUBLIC_URL}/`) && (
            <Redirect to="/" />
          )}
        </Router>
        {/* <Router basename={process.env.PUBLIC_URL}>
          <Route path={'/'} component={Routes} />
        </Router> */}
        {/* </SocketContext.Provider> */}
      </ThemeProvider> 
      {
        // console.log('document.cookie', Cookies.get('cinemawallaWebCookiesAccept'))
      } 
      {
        // !Cookies.get('cinemawallaWebCookiesAccept') && (path !== '/privacy-policy' || !path.includes('appplayer'))?
        // <div className="cookie-container">
        //   <div className="cookie-card">
        //   <h4>We use Cookies!</h4>
        //   <p className="cookie-text">
        //     This website uses cookies to give our users the best experience. For more information, please read our <a href="https://cinemawalla.com/privacy-policy" target="_blank"> Privacy Policy.</a>
        //   </p>
        //   <div className="agree">
        //     <button onClick={()=>{
        //       // Set-Cookie: cinemawallaWebCookiesAccept=true
        //       // document.cookie = "cinemawallaWebCookiesAccept=true";
        //       Cookies.set('cinemawallaWebCookiesAccept', true, { expires: 365, domain: 'cinemawalla.com' });
        //       window.location.reload();
        //     }}>Allow All Cookies</button>
        //   </div>
        //   </div>
        // </div>
        // :null
        
      }
    </ConfigProvider>
  );
};

function App() {

  return (
    <SocketContext.Provider value={socket}>
      <Provider store={store}>
        <ProviderConfig />
      </Provider>
    </SocketContext.Provider>
  );
}

export default App;
