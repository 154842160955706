import Cookies from 'js-cookie';
import actions from './actions';
import {DataService} from '../../config/dataService/dataService';
import { triggerFocus } from 'antd/lib/input/Input';
import { message } from 'antd';
import { API } from '../../config/api';
const { loginBegin, loginSuccess, loginVarify, loginErr, signupBegin, forgotErr, forgotBegin , forgotSuccess, recoverErr, recoverBegin , recoverSuccess, verifyErr, verifyBegin , verifySuccess, signupSuccess, signupErr, logoutBegin, logoutSuccess, logoutErr } = actions;
const key = 'updatable';


const login = (data) => {
  return async dispatch => {
    try {
      dispatch(loginBegin());
      const postdata = sessionStorage.getItem('cinemawallaWebFCMToken');
      data['fcmToken'] = postdata;
      let response = await DataService.post('auth/login', data);
      if(await response.data.success){
        await localStorage.setItem('cinemawallaWebUser', window.btoa(JSON.stringify(response.data.data)));
        await localStorage.setItem('access_token', window.btoa(response.data.data.token));
        await Cookies.set('cinemawallaWebLogedIn', true);
        await message.success({ content:  response.data.message, duration: 2 });
        return await dispatch(loginSuccess(response.data.data));
        // {response.data.message && response.data.message.title?
        //   message.success({ content:  response.data.message.title, duration: 2 })
        // :null}
        // {response.data.message && response.data.message.message?
        //   message.success({ content:  response.data.message.message, duration: 2 })
        // :null}
        
      }else{
        message.error({ content:  response.data.message, duration: 2 });
        // {response.data.message && response.data.message.title?
        //   message.error({ content:  response.data.message.title, duration: 2 })
        // :null}
        // {response.data.message && response.data.message.message?
        //   message.error({ content:  response.data.message.message, duration: 2 })
        // :null}
        return dispatch(loginErr(response.data.message));
      }
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};


const Signup = (data) => {
  return async dispatch => {
    try {
      dispatch(signupBegin());
      let response = await DataService.post('auth/register', data);
      if(response.data.success){
        message.success({ content:  response.data.message, duration: 2 });

        // {response.data.message && response.data.message.message?
        //   message.success({ content: response.data.message.message, duration: 4 })
        // :null}
        
        return dispatch(signupSuccess(true));
      }else{
        message.error({ content:  response.data.message, duration: 2 })
        
        // {response.data.message && response.data.message.message?
        //   message.error({ content:  response.data.message.message, duration: 2 })
        // :null}
        return dispatch(signupErr(response.data.message));
      }
    } catch (err) {
      dispatch(signupErr(err));
    }
  };
};


const Forgot = (data) => {
  return async dispatch => {
    try {
      dispatch(forgotBegin());
      let response = await DataService.post('auth/forgotpassword', data);
      if(response.data.success){
        message.success({ content: response.data.message, duration: 2 })
        // {response.data.message && response.data.message.title?
        //   message.success({ content: response.data.message.title, duration: 2 })
        // :null}
        // {response.data.message && response.data.message.message?
        //   message.success({ content: response.data.message.message, duration: 4 })
        // :null}
        return dispatch(forgotSuccess(true));
      }else{
        message.error({ content: response.data.message, duration: 2 })
        // {response.data.message && response.data.message.title?
        //   message.error({ content:  response.data.message.title, duration: 2 })
        // :null}
        // {response.data.message && response.data.message.message?
        //   message.error({ content:  response.data.message.message, duration: 2 })
        // :null}
        return dispatch(forgotErr(response.data.message));
      }
    } catch (err) {
      dispatch(forgotErr(err));
    }
  };
};

const VerifyEmail = (data) => {
  return async dispatch => {
    try {
      dispatch(verifyBegin());
      let response = await DataService.post('auth/verifyOtp', data);
      if(response.data.success){
        message.success({ content: response.data.message, duration: 2 })
        // {response.data.message && response.data.message.message?
        //   message.success({ content: response.data.message.message, duration: 4 })
        // :null}
        return dispatch(verifySuccess(true));
      }else{
        message.success({ content: response.data.message, duration: 2 })
        // {response.data.message && response.data.message.message?
        //   message.error({ content:  response.data.message.message, duration: 2 })
        // :null}
        return dispatch(verifyErr(response.data.message));
      }
    } catch (err) {
      dispatch(verifyErr(err));
    }
  };
};

const VerifySuspention = (data) => {
  return async dispatch => {
    try {
      dispatch(verifyBegin());
      let response = await DataService.post('auth/verifysuspention', data);
      if(response.data.success){
        message.success({ content: response.data.message, duration: 2 })
        // {response.data.message && response.data.message.message?
        //   message.success({ content: response.data.message.message, duration: 4 })
        // :null}
        return dispatch(verifySuccess(true));
      }else{
        message.success({ content: response.data.message, duration: 2 })
        // {response.data.message && response.data.message.message?
        //   message.error({ content:  response.data.message.message, duration: 2 })
        // :null}
        return dispatch(verifyErr(response.data.message));
      }
    } catch (err) {
      dispatch(verifyErr(err));
    }
  };
};

const ResendCode = (data) => {
  return async dispatch => {
    try {
      dispatch(verifyBegin());
      let response = await DataService.post('auth/resendVerifyOtp', data);
      if(response.data.success){
        message.success({ content: response.data.message, duration: 2 })
        // {response.data.message && response.data.message.title?
        //   message.success({ content: response.data.message.title, duration: 2 })
        // :null}
        // {response.data.message && response.data.message.message?
        //   message.success({ content: response.data.message.message, duration: 4 })
        // :null}
        return dispatch(verifySuccess(true));
      }else{
        message.success({ content: response.data.message, duration: 2 })
        // {response.data.message && response.data.message.title?
        //   message.error({ content:  response.data.message.title, duration: 2 })
        // :null}
        // {response.data.message && response.data.message.message?
        //   message.error({ content:  response.data.message.message, duration: 2 })
        // :null}
        return dispatch(verifyErr(response.data.message));
      }
    } catch (err) {
      dispatch(verifyErr(err));
    }
  };
};

const RecoverPassword = (data) => {
  return async dispatch => {
    try {
      dispatch(recoverBegin());
      let response = await DataService.put('auth/resetpassword/'+data.id, data);
      if(response.data.success){
        message.success({ content: response.data.message, duration: 2 })
        // {response.data.message && response.data.message.title?
        //   message.success({ content: response.data.message.title, duration: 2 })
        // :null}
        // {response.data.message && response.data.message.message?
        //   message.success({ content: response.data.message.message, duration: 4 })
        // :null}
        return dispatch(recoverSuccess(true));
      }else{
        message.error({ content: response.data.message, duration: 2 })
        // {response.data.message && response.data.message.title?
        //   message.error({ content:  response.data.message.title, duration: 2 })
        // :null}
        // {response.data.message && response.data.message.message?
        //   message.error({ content:  response.data.message.message, duration: 2 })
        // :null}
        return dispatch(recoverErr(response.data.message.title?response.data.message.title:response.data.message.message));
      }
    } catch (err) {
      dispatch(recoverErr(err));
    }
  };
};

const RecoverPin = (data) => {
  return async dispatch => {
    try {
      dispatch(recoverBegin());
      let response = await DataService.put('auth/resetpin/'+data.id, data);
      if(response.data.success){
        message.success({ content: response.data.message, duration: 2 })
        // {response.data.message && response.data.message.title?
        //   message.success({ content: response.data.message.title, duration: 2 })
        // :null}
        // {response.data.message && response.data.message.message?
        //   message.success({ content: response.data.message.message, duration: 4 })
        // :null}
        return dispatch(recoverSuccess(true));
      }else{
        message.error({ content: response.data.message, duration: 2 })
        // {response.data.message && response.data.message.title?
        //   message.error({ content:  response.data.message.title, duration: 2 })
        // :null}
        // {response.data.message && response.data.message.message?
        //   message.error({ content:  response.data.message.message, duration: 2 })
        // :null}
        return dispatch(recoverErr(response.data.message.title?response.data.message.title:response.data.message.message));
      }
    } catch (err) {
      dispatch(recoverErr(err));
    }
  };
};

const ChangePassword = (data) => {
  return async dispatch => {
    try {
      dispatch(recoverBegin());
      let response = await DataService.put('user/change_password/'+ data.id, data);

      // console.log('response', response);

      if(response.data.success){
        if(response.data.message){
          message.success({ content: response.data.message, duration: 2 })
        }
        return dispatch(recoverSuccess(true));
      }else{
        if(response.data.message){
          message.error({ content: response.data.message, duration: 2 })
        }
        return dispatch(recoverErr(response.data.message.title?response.data.message.title:response.data.message.message));
      }
    } catch (err) {
      dispatch(recoverErr(err));
    }
  };
};

const ChangePin  = (data) => {
  return async dispatch => {
    try {
      dispatch(recoverBegin());
      let response = await DataService.put('user/change_pin/'+ data.id, data);

      // console.log('response', response);

      if(response.data.success){
        if(response.data.message){
          message.success({ content: response.data.message, duration: 2 })
        }
        return dispatch(recoverSuccess(true));
      }else{
        if(response.data.message){
          message.error({ content: response.data.message, duration: 2 })
        }
        return dispatch(recoverErr(response.data.message.title?response.data.message.title:response.data.message.message));
      }
    } catch (err) {
      dispatch(recoverErr(err));
    }
  };
};


const logOut = () => {
  return async dispatch => {
    try {
      dispatch(logoutBegin());
      Cookies.remove('cinemawallaWebLogedIn');
      Cookies.remove('cinemawallaWebUser');
      localStorage.removeItem('cinemawallaWebUser');
      // localStorage.removeItem('cinemawallaWebUserMatrix');
      localStorage.removeItem('access_token');
      window.location.href = window.location.origin;
      dispatch(logoutSuccess(null));
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

const openLogin = () => {
  return true;
};

export { login, logOut, openLogin, ResendCode, Signup, Forgot, VerifyEmail, RecoverPassword, VerifySuspention, ChangePassword, ChangePin, RecoverPin };
