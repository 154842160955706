import React from "react";
import "./videojs/skins/shaka/videojs.min.css";
import "./videojs/skins/treso/ima.css";
import videojs from "video.js";
import "./videojs/components/ima.js";
import "videojs-contrib-ads";
import urlParser from "js-video-url-parser";
import "./videojs/components/nuevo.js";
import "./videojs/components/thumbnails.js";
import "./videojs/components/vroll.js";
import Player from './player';
import addVideo from '../../assets/images/AfterAdsPlayback.mp4';
import { getScheduleMovieById } from "../../redux/movies/actionCreator";
import { API } from "../../config/api";
import { Spin } from "antd";
// import "../../../assets/js/Youtube";
// import "../../../assets/js/Vimeo";
// import "../../../assets/js/Dailymotion";
// import "../../../assets/js/Wistia";
// import "../../../assets/js/Vast";
// import "../../../assets/js/Twitch";

const  vastPlayerJs = 'https://cinemawalla.com/videojs/plugins/vast.vpaid.min.js';
let player;
let videoNode;
export default class VideoPlayer extends React.Component {
 
  constructor(props) {
    super(props);
    this.state = {
      ads: true,
      movie:{},
      userData:{},
      roomID:null,
      scheduleId:{},
      schedule:null,
      adTagUrl:null
    };
  }

  componentDidMount() {
    const movie = this.props.movie;
    const userData = this.props.userData;
    const scheduleId = this.props.scheduleId;
    const schedule = this.props.schedule;
    console.log('movie', scheduleId)
    // if(schedule && schedule.chatRoomId){
     
    // }
    const age = Number(new Date().getFullYear()) - Number(userData.year_of_birth);
    // https://adservice.cinevisualis.com/fc.php?script=rmVideo&zoneid=7&format=vast_pod&filmtype=${movie.domainType}&userid=${userData.id}&&age=${age}&loc=${window.origin}
    // console.log('domainType', movie.domainType);
    // console.log('userid', userData.id);
    // console.log('age', age);
    
    const adTagUrl =  `https://adservice.cinevisualis.com/fc.php?script=rmVideo&zoneid=7&format=vast_pod&filmtype=${movie.domainType}&userid=${userData.id}&&age=${age}&loc=${'https://imasdk.googleapis.com'}`;

    this.setState({
      movie:movie,
      userData:userData,
      schedule:schedule,
      scheduleId:scheduleId,
      adTagUrl: adTagUrl
    });
    // const controls = schedule.scheduled_by === userData.id;
  //   const info = urlParser.parse(movie.movieUrl);
  // controls": false, "autoplay": true, "preload": "auto"
    const videoJsOptions = {
      preload: "auto",
      controls: false,
      autoplay: true,
      poster: movie.poster,
      sources: [
        { type: `video/mp4`, src: addVideo }
      ]
   };
    player = videojs(
      videoNode,
      videoJsOptions,
      function onPlayerReady() {
        console.log("Player ready.");
        // Laod Vast/Vpaid plugin and initialize ad
        // window.videojs = videojs;
        // const script = document.createElement("script");
        // script.src= vastPlayerJs;
        // document.body.appendChild(script);
        // script.onload = () => {
        //   player.vastAds ({ tagURL: `https://adservice.cinevisualis.com/fc.php?script=rmVideo&zoneid=7&format=vast&filmtype=${
        //     movie.domainType
        //   }&userid=${userData}&&age=${age}&loc=${window.origin}` });
        // }
        // https://adservice.cinevisualis.com/fc.php?script=rmVideo&zoneid=7&format=vast&filmtype=${'public'}&userid=${userDetails.id}&&age=${age}&loc=${window.origin}
      }
    );
  
    const nuevoOptions = {
      logo: "https://cinemawalla.com/images/pic01.jpg",
      logourl: "https://cinemawalla.com",
      // buttonForward: false,
      // rewindforward: false,
      // theaterButton: true,
      // controls: false,
      // shareMenu: false,
      // settingsButton: false,
      // pipButton: false,
      // resume:true,
    };

    this.player = player;
    this.player.nuevo(nuevoOptions);
    // Define Google Ima Ads Tag

    this.player.ima({adTagUrl: adTagUrl});
    this.player.on('ended', () => {    
      console.log('asasaasas', 'END'); 
      this.setState({ads:false});
    });
    // this.player.vroll([
    //   {
    //       src: "https://static.videezy.com/system/resources/previews/000/043/977/original/DSC_8447_V1-0010.mp4",
    //       type: "video/mp4",
    //       href: "https://www.codesolution.co.in/",
    //       offset: "0",
    //       skip: "5"
    //   },
    //   {
    //       src: "https://static.videezy.com/system/resources/previews/000/000/168/original/Record.mp4",
    //       type: "video/mp4",
    //       href: "https://www.codesolution.co.in/",
    //       offset: "25",
    //       skip: "5"
    //   }
    // ]);
  }

  

  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  render() {
    return (
      this.props.userData && this.props.movie? 
        this.state.ads?
          <div data-vjs-player>
            <video playsInline={true} ref={(node) => (videoNode = node)} className="video-js vjs-fluid" />
          </div>
        :<Player userData={this.props.userData} movie={this.props.movie} scheduleId={this.props.scheduleId} schedule={this.props.schedule} appPlayer={this.props.appPlayer} socketIo={this.props.socketIo} />
      :<div className='spin'><Spin /></div>
    );
  }
}
