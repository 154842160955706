import React, { useEffect, useState } from 'react';
import { LoginContainer } from './style';
import Cookies from 'js-cookie';
import { NavLink, useHistory } from 'react-router-dom';
import { Form, Input, Row, Col, Button, Checkbox, message } from 'antd';
import config from '../../config/config';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../redux/authentication/actionCreator';
import { getCookies } from '../../utility/localStorageControl';
import { addSubscription } from '../../redux/subscription/actionCreator';

const { theme, images } = config;

const Login = (props) => {
    const [form] = Form.useForm();
    const [formSubscription] = Form.useForm();
    
    const history = useHistory();
    const isLoading = useSelector(state => state.auth.loading);
    const dispatch = useDispatch();

    const submitForm = async (data) => {
        data.userEmailPhone = data.userEmailPhone.toLowerCase();
        if(data.remember){
            const obj ={
                userEmailPhone:data.userEmailPhone.toLowerCase(),
                password:data.password,
                remember:data.remember
            }
            await Cookies.set('cinemawallaWebusername', window.btoa(JSON.stringify(obj)));
        }
        data.passType = 'PASS';
        if(data.password.length === 4){
            data.passType='PIN';
        }
        let returndata = await dispatch(login(data));
        console.log('returndata', returndata);
        if(returndata.type=="LOGIN_SUCCESS"){
            props.close();
                // history.push('/');
            window.location.reload();
        }else if(returndata.type=="LOGIN_ERR"){
            
        } 
    }

    const submitSubscription = async (values) => {
        let returndata = await dispatch(addSubscription(values));
        if(returndata.type=="SUBSCRIPTION_SUCCESS"){
            props.close();
                // history.push('/');
            window.location.reload();
        }else if(returndata.type=="SUBSCRIPTION_ERR"){
            
        } 
    }

    const getRememberMeData = async () => {
        const rememberData = await Cookies.get('cinemawallaWebusername');
        if(rememberData){
            const formData = JSON.parse(window.atob(rememberData));
            form.setFieldsValue({...formData});
        }

    }

    useEffect(() => {
        getRememberMeData();
    }, []);

    return (
        <LoginContainer>
            <h3 className="heading">Login </h3>
            <Form className="login-form" name="control-ref" form={form} onFinish={submitForm} >
                <Form.Item name="userEmailPhone" rules={[{ required: true, message: 'This field is required' }]} >
                    <Input placeholder="Email/Username" />
                </Form.Item>
                <Form.Item name="password" rules={[{ required: true, message: 'This field is required' }]}>
                    <Input.Password placeholder="Password/PIN" />
                </Form.Item>
                <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', color:'#fff' }}>
                    <Col span={12}>
                        <Form.Item className="login-form-remember" name="remember" valuePropName="checked">
                            <Checkbox>Remember me</Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <Form.Item>
                            <NavLink onClick={()=>props.close()} className="login-form-forgot" to={'/forgot'}>
                                Forgot password
                            </NavLink>
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                        Log in
                    </Button>
                </Form.Item>
                <p className="signup-link"> Dont't have an account? <NavLink onClick={()=>props.close()} to={'/registration'}> Sign up </NavLink> </p>
                
            </Form>
            <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', borderWidth:1, borderColor:"#fff" }}>
                {/* <Col span={24}>
                    <h3 className="downlaod-heading">Sent request to enrol as a beta tester for CinemaWalla APP (Android Version)</h3>
                </Col>
                <Col span={24}>
                    <Form className="login-form" name="control-ref" form={formSubscription} onFinish={submitSubscription} >
                        <Form.Item name="name" rules={[{ required: true, message: 'This field is required' }]} >
                            <Input placeholder="Enter your name" />
                        </Form.Item>
                        <Form.Item name="email" rules={[{ type: 'email', required: true, message: 'This field is required' }]} >
                            <Input placeholder="Enter your email" />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="login-form-button">
                                Send
                            </Button>
                        </Form.Item>
                    </Form>
                </Col> */}
                <Col span={24}>
                    <h3 className="downlaod-heading">Download our Mobile App</h3>
                </Col>
                <Col span={12} style={{padding:10}}>
                    <a href="https://play.google.com/store/apps/details?id=com.cinemawalla" target={'_blank'}><img src={images.googlePlay} style={{width:'100%'}} /></a>
                </Col>
                <Col span={12} style={{padding:10}}>
                   <a href='https://apps.apple.com/us/app/cinemawalla/id6446685244' target={'_blank'}><img src={images.apple} style={{width:'100%'}} /></a> 
                </Col>
            </Row>
        </LoginContainer>
    );
};

export default Login;
