const actions = {
  
    SUBSCRIPTION_BEGIN: 'SUBSCRIPTION_BEGIN',
    SUBSCRIPTION_SUCCESS: 'SUBSCRIPTION_SUCCESS',
    SUBSCRIPTION_ERR: 'SUBSCRIPTION_ERR',

    subscriptionBegin: () => {
      return {
        type: actions.SUBSCRIPTION_BEGIN,
      };
    },
  
    subscriptionSuccess: data => {
      return {
        type: actions.SUBSCRIPTION_SUCCESS,
        data,
      };
    },
  
    subscriptionErr: err => {
      return {
        type: actions.SUBSCRIPTION_ERR,
        err,
      };
    },
  
  };
  
  export default actions;
  