import actions from './actions';
import {DataService} from '../../config/dataService/dataService';
import { message } from 'antd';
const {
  searchBegin,
  searchSuccess,
  searchErr,

  filterSuccess,
  searchTextSuccess,

} = actions;


const getSearch = data => {
  return async dispatch => {
    try {
      dispatch(searchBegin());
      let response = await DataService.post(`movie/search`, data);
      if(response.data.success){
        // message.success({ content:  response.data.message, duration: 2 })
        // {response.data.message && response.data.message.title?
        //   message.success({ content:  response.data.message.title, duration: 2 })
        // :null}
        // {response.data.message && response.data.message.message?
        //   message.success({ content:  response.data.message.message, duration: 2 })
        // :null}
        return dispatch(searchSuccess(response.data.data));
      }else{
        {response.data.message?
          message.error({ content:  response.data.message, duration: 2 })
        :message.error({ content:  'Something went wrong!', duration: 2 })}
        // {response.data.message && response.data.message.title?
        //   message.error({ content:  response.data.message.title, duration: 2 })
        // :null}
        // {response.data.message && response.data.message.message?
        //   message.error({ content:  response.data.message.message, duration: 2 })
        // :null}
        return dispatch(searchErr(response.data.message));
      }
      
    } catch (err) {       dispatch(searchErr(err));
    }
  };
};

const setFilter = data => {
  return async dispatch => {
    try {
      return dispatch(filterSuccess(data));
      // let response = await DataService.post(`movie/search`, data);
      // if(response.data.success){
      //   // message.success({ content:  response.data.message, duration: 2 })
      //   // {response.data.message && response.data.message.title?
      //   //   message.success({ content:  response.data.message.title, duration: 2 })
      //   // :null}
      //   // {response.data.message && response.data.message.message?
      //   //   message.success({ content:  response.data.message.message, duration: 2 })
      //   // :null}
      //   return dispatch(searchSuccess(response.data.data));
      // }else{
      //   {response.data.message?
      //     message.error({ content:  response.data.message, duration: 2 })
      //   :message.error({ content:  'Something went wrong!', duration: 2 })}
      //   // {response.data.message && response.data.message.title?
      //   //   message.error({ content:  response.data.message.title, duration: 2 })
      //   // :null}
      //   // {response.data.message && response.data.message.message?
      //   //   message.error({ content:  response.data.message.message, duration: 2 })
      //   // :null}
      //   return dispatch(searchErr(response.data.message));
      // }
      
    } catch (err) {       dispatch(searchErr(err));
    }
  };
};

const setSearchText = data => {
  return async dispatch => {
    try {

      if(data.length === 0 || data.length > 3){
        return dispatch(searchTextSuccess(data));
      }
     
      // let response = await DataService.post(`movie/search`, data);
      // if(response.data.success){
      //   // message.success({ content:  response.data.message, duration: 2 })
      //   // {response.data.message && response.data.message.title?
      //   //   message.success({ content:  response.data.message.title, duration: 2 })
      //   // :null}
      //   // {response.data.message && response.data.message.message?
      //   //   message.success({ content:  response.data.message.message, duration: 2 })
      //   // :null}
      //   return dispatch(searchSuccess(response.data.data));
      // }else{
      //   {response.data.message?
      //     message.error({ content:  response.data.message, duration: 2 })
      //   :message.error({ content:  'Something went wrong!', duration: 2 })}
      //   // {response.data.message && response.data.message.title?
      //   //   message.error({ content:  response.data.message.title, duration: 2 })
      //   // :null}
      //   // {response.data.message && response.data.message.message?
      //   //   message.error({ content:  response.data.message.message, duration: 2 })
      //   // :null}
      //   return dispatch(searchErr(response.data.message));
      // }
      
    } catch (err) {       dispatch(searchErr(err));
    }
  };
};




export { getSearch, setFilter, setSearchText };
