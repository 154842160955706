import actions from './actions';

const {
  SENDMESSAGE_BEGIN,
  SENDMESSAGE_SUCCESS,
  SENDMESSAGE_ERR, 
 
  MESSAGES_BEGIN,
  MESSAGES_SUCCESS,
  MESSAGES_ERR, 

  MEMBERS_BEGIN,
  MEMBERS_SUCCESS,
  MEMBERS_ERR, 

  Socket_BEGIN,
  Socket_SUCCESS,
  Socket_ERR, 
   

} = actions;

const initialStateList = {
  data: [],
  loading: false,
  error: null,
};

const SendMessageReducer = (state = initialStateList, action) => {
  const { type, data, err } = action;
  switch (type) {
    case SENDMESSAGE_BEGIN:
      return {
        ...initialStateList,
        loading: true,
      };
    case SENDMESSAGE_SUCCESS:
      return {
        ...initialStateList,
        data,
        loading: false,
      };
    case SENDMESSAGE_ERR:
      return {
        ...initialStateList,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

const initialMessageList = {
  data: [],
  loading: false,
  error: null,
};


const initialMembersList = {
  data: [],
  loading: false,
  error: null,
};

const GetMessagesReducer = (state = initialMessageList, action) => {
  const { type, data, err } = action;
  switch (type) {
    case MESSAGES_BEGIN:
      return {
        ...initialStateList,
        loading: true,
      };
    case MESSAGES_SUCCESS:
      return {
        ...initialStateList,
        data,
        loading: false,
      };
    case MESSAGES_ERR:
      return {
        ...initialStateList,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

const GetMembersReducer = (state = initialMembersList, action) => {
  const { type, data, err } = action;

  switch (type) {
    case MEMBERS_BEGIN:
      return {
        ...initialMembersList,
        loading: true,
      };
    case MEMBERS_SUCCESS:
      return {
        ...initialMembersList,
        data,
        loading: false,
      };
    case MEMBERS_ERR:
      return {
        ...initialMembersList,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};


const initialSocketList = {
  data: {},
  loading: false,
  error: null,
};

const SocketReducer = (state = initialMembersList, action) => {
  const { type, data, err } = action;

  switch (type) {
    case Socket_BEGIN:
      return {
        ...initialSocketList,
        loading: true,
      };
    case Socket_SUCCESS:
      return {
        ...initialSocketList,
        data,
        loading: false,
      };
    case Socket_ERR:
      return {
        ...initialSocketList,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
}

export { SendMessageReducer, GetMessagesReducer, GetMembersReducer, SocketReducer };
