import React from 'react';
import { Menu, Button, message, Modal } from 'antd';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getItem } from '../utility/localStorageControl';
import FeatherIcon from 'feather-icons-react';
const { SubMenu } = Menu;

const MenuItems = ({ toggleCollapsed, SignOut, showMessage }) => {
  const { path } = useRouteMatch();
  const pathName = window.location.pathname;
  const userData = getItem('cinemawallaWebUser');
  // const { userData } = useSelector(state => {
  //   return {
  //     userData: state.auth.data
  //   };
  // });
 
  // console.log('userData', userData);

  // const showMessage = () => {
  //   if(userData.profileComplete){

  //   }else{
  //     // modal.info(config);
  //     message.info({ content:  'To became a "Film Maker" complete your profile', duration: 2 });
  //   }
   
  // }

  return (
    <Menu>
      {/* icon={!topMenu && <FeatherIcon icon="home" />} */}
      <Menu.Item  key="home">
        <NavLink onClick={toggleCollapsed} to={`${path}`}>
          Home
        </NavLink>
      </Menu.Item>
      {
        userData && userData.id?
          <>
            <Menu.Item  key="profile">
              <NavLink onClick={toggleCollapsed} to={`${path}profile`}>
                My Profile
              </NavLink>
            </Menu.Item>
            <Menu.Item  key="reports">
              <NavLink onClick={toggleCollapsed} to={`${path}reports`}>
                Film Analytics
              </NavLink>
            </Menu.Item>
            {
              userData.role === 'watcher'?
                <Menu.Item  key="filmmaker">
                  <NavLink onClick={() => { toggleCollapsed(); showMessage(userData); }} to={ `${ userData.profileComplete ?  path+'addmovie': path+'profile/edit' }` } >
                    {
                      userData.profileComplete ?
                        'Submit your film' : 'Submit your film'
                    }
                  </NavLink>
                </Menu.Item>
              :null
            }
            {
              userData.role === 'maker'?
                <>
                  <Menu.Item  key="addmovies">
                    <NavLink onClick={toggleCollapsed} to={`${path}addmovie`}>
                      Upload Film
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item  key="movies">
                    <NavLink onClick={toggleCollapsed} to={`${path}movies/uploaded`}>
                      My Films Vault
                    </NavLink>
                  </Menu.Item>
                </>
              :null
            }
            
            
            <Menu.Item  key="favourite">
              <NavLink onClick={toggleCollapsed} to={`${path}movies/favourite`}>
                Favourite Films Library
              </NavLink>
            </Menu.Item>
            <Menu.Item  key="reviews">
              <NavLink onClick={toggleCollapsed} to={`${path}movies/reviews`}>
                My Reviews
              </NavLink>
            </Menu.Item>
            <Menu.Item  key="friends">
              <NavLink onClick={toggleCollapsed} to={`${path}friends`}>
                Cinephile Friends Directory
              </NavLink>
            </Menu.Item>
            <Menu.Item  key="scheduled">
              <NavLink onClick={toggleCollapsed} to={`${path}scheduled`}>
                Film Scheduling
              </NavLink>
            </Menu.Item>
            <Menu.Item  key="timeline">
              <NavLink onClick={toggleCollapsed} to={`${path}timeline`}>
                Timeline
              </NavLink>
            </Menu.Item>
          </>
        :null
      }
      <Menu.Item  key="about">
        <NavLink onClick={toggleCollapsed} to={`${path}about`}>
          About Cinema Walla
        </NavLink>
      </Menu.Item>
      <Menu.Item  key="faq">
        <NavLink onClick={toggleCollapsed} to={`${path}faq`}>
          Faq's
        </NavLink>
      </Menu.Item>
      <Menu.Item  key="support">
        <NavLink onClick={toggleCollapsed} to={`${path}support`}>
           {userData && userData.id?'Support Center':'Support Center'} 
        </NavLink>
      </Menu.Item>
      {
        userData && userData.id?
          <>
            <Menu.Item  key="settings">
              <NavLink onClick={toggleCollapsed} to={`${path}settings`}>
                Settings
              </NavLink>
            </Menu.Item>
            <Menu.Item  key="logout">
              <Button className="logout-button" style={{ padding: 0, color: '#ffffff', fontSize: 16 }} type="text" onClick={()=> { toggleCollapsed(); SignOut(); }} >
                Logout
              </Button>
            </Menu.Item>
          </>
        :null
      }
      
    </Menu>
  );
};

export default MenuItems;
