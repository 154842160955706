import { theme, themeColors } from './theme/themeVariables';
import { images } from './theme/images';
import { DataService } from './dataService/dataService';
const config = {
  theme,
  themeColors,
  authRouts: ['/register', '/verifyemail', '/forgotpassword', '/recoverypassword'],
  imagePath: DataService.getBaseUrl(),
  images
};

export default config;
