import { combineReducers } from 'redux';
import authReducer from './authentication/reducers';
import SearchReducer from './search/reducers';
import FilterReducer from './search/reducersFilter';
import SearchTextReducer from './search/reducerSearchText';
import SchadulePopupReducer from './schadulePopup/reducer';
import { NotificationReducer, UnreadNotificationReducer } from './notification/reducers';
import { SocketReducer, GetMessagesReducer, GetMembersReducer } from './messages/reducers';
const rootReducers = combineReducers({  
  auth: authReducer,
  socket: SocketReducer,
  search: SearchReducer,
  filter: FilterReducer,
  searchText: SearchTextReducer,
  SchadulePopup: SchadulePopupReducer,
  notification: NotificationReducer,
  unreadnotification: UnreadNotificationReducer,
  messages: GetMessagesReducer,
  members: GetMembersReducer,
});

export default rootReducers;
