/**
 * Return ellipsis of a given string
 * @param {string} text
 * @param {number} size
 */



const ellipsis = (text, size) => {
  return `${text
    .split(' ')
    .slice(0, size)
    .join(' ')}...`;
};

const timer = (time) => {
  // let downloadTimer = setInterval(function(){
  //   if(timeleft <= 0){
  //     clearInterval(downloadTimer);
  //     return 'true';
  //     // document.getElementById("countdown").innerHTML = "Finished";
  //   } else {
  //     return timeleft;
  //     // document.getElementById("countdown").innerHTML = timeleft + " seconds remaining";
  //   }
  //   timeleft -= 1;
  // }, 1000);
  let seconds = time % 60;
  let minutes = Math.floor(time / 60);
  minutes = minutes.toString().length === 1 ? "0" + minutes : minutes;
  seconds = seconds.toString().length === 1 ? "0" + seconds : seconds;
  return minutes + ':' + seconds;
}

const ArrayFieldcount = (array, key) => {
  let total =0;
  array.map(a => {
    if(a[key] && Number(a[key]) > 0){
      total = Number(total) + Number(a[key]);
    }
  });
  return total;
}

const yearsListData = (minYear) => {
  let data = [];
  const nowYear = new Date().getUTCFullYear();
  for (let index = nowYear; index >= minYear; index--) {
    data.push(index);
    if(index === minYear){
      return data;
    }
  }
};

const getYearRange = (year) => {
  let data = [];
  let CIndex = 0;
  const list = yearsListData(year);
  list.forEach((element, index) => {
    if(index === CIndex){
      if(index > 0){
        if(element+1 === new Date().getFullYear()){
          data.push(`${new Date().getFullYear()}-Onwards`);
        } else if(element+10 > new Date().getFullYear()){
          data.push(`${element+1}-${new Date().getFullYear()}`);
        }else{
          data.push(`${element+1}-${element+10}`);
        } 
      }else{
        data.push(`${element}-${element+10}`);
      }
      CIndex = CIndex+10;
    }
  });
  return data;
}

const showLogin = () => {
  return true;
};

const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function(txt){
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

const setDashboardGraph = async (result) => {
  let xValues = [];
  let yValue1 = [];
  let recurringText = 0;
  let oneShotText = 0;
  let yValue2 = [];
  await Object.entries(result).forEach(async([key, value]) => {
    let recurring = 0;
    let oneShot = 0;
    let newValue = await value.reduce(function (r, a) {
      r[a.student_id] = r[a.student_id] || [];
      r[a.student_id].push(a);
      return r;
    }, Object.create(null));
    await Object.entries(newValue).forEach(([key1, value1]) => {
      if(value1.length>1){
        recurring = Number(recurring) + Number(value1.length);
      }else{
        oneShot = Number(oneShot) + Number(value1.length);
      }
    });
    xValues.push(key);
    yValue1.push(recurring);
    yValue2.push(oneShot);
    recurringText = Number(recurringText) + recurring;
    oneShotText = Number(oneShotText) + oneShot;
    console.log(recurringText, oneShotText);
    
  });
  
  return {
    x:xValues,
    data1: yValue1,
    data2: yValue2
  }
}


const getNumberCards = () => {
  if(window.innerWidth >= 1200){
      return 4;
  }else if(window.innerWidth >= 992 && window.innerWidth < 1200){
      return 3;
  }else if(window.innerWidth >= 480 && window.innerWidth < 992){
      return 2;
  }else if(window.innerWidth < 480){
      return 1;
  }
}

const getItemCardsWidth = () => {
  if(window.innerWidth >= 1200){
      return 24;
  }else if(window.innerWidth >= 992 && window.innerWidth < 1200){
      return 32;
  }else if(window.innerWidth >= 480 && window.innerWidth < 992){
      return 48;
  }else if(window.innerWidth < 480){
      return 100;
  }
}

const getColSize = () => {
  if(window.innerWidth >= 1200){
      return 6;
  }else if(window.innerWidth >= 992 && window.innerWidth < 1200){
      return 8;
  }else if(window.innerWidth >= 480 && window.innerWidth < 992){
      return 12;
  }else if(window.innerWidth < 480){
      return 24;
  }
}

const getScheduledColSize = () => {
  if(window.innerWidth >= 1200){
      return 8;
  }else if(window.innerWidth >= 992 && window.innerWidth < 1200){
      return 12;
  }else if(window.innerWidth >= 600 && window.innerWidth < 992){
      return 12;
  }else if(window.innerWidth < 600){
      return 24;
  }
}


export { ellipsis, showLogin, ArrayFieldcount, toTitleCase, getYearRange, timer, yearsListData, setDashboardGraph, getNumberCards, getItemCardsWidth, getColSize, getScheduledColSize };
