import actions from './actions';

const {
  SEARCH_BEGIN,
  SEARCH_SUCCESS,
  SEARCH_ERR,

} = actions;

const initialStateList = {
  data: [],
  loading: false,
  error: null,
};

const SearchReducer = (state = initialStateList, action) => {
  const { type, data, err } = action;
  switch (type) {
    case SEARCH_BEGIN:
      return {
        ...initialStateList,
        loading: true,
      };
    case SEARCH_SUCCESS:
      return {
        ...initialStateList,
        data,
        loading: false,
      };
    case SEARCH_ERR:
      return {
        ...initialStateList,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

export default SearchReducer;
