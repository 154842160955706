import actions from './actions';
import {DataService} from '../../config/dataService/dataService';
import { message } from 'antd';
import socketIOClient from 'socket.io-client/dist/socket.io';
import { db } from '../../config/firebase';
const {
  // sendMessageBegin,
  // sendMessageSuccess,
  // sendMessageErr,

  // messagesBegin,
  // messagesSuccess,
  // messagesErr,

  // membersBegin,
  // membersSuccess,
  // membersErr,

    socketBegin,
    socketSuccess,
    socketErr

} = actions;

const connectSocket = () => {
  return async dispatch => {
    try {
      // alert('sasasaasasassas asasasasas');
      dispatch(socketBegin());
      const socketIo = socketIOClient("https://app.cinemawalla.com", {
          transports: ['websocket'],
          reconnection: true,
          reconnectionDelay: 1000,
          reconnectionDelayMax : 5000,
          reconnectionAttempts: Infinity
      });
      console.log('socketIo  ==   socketIo', socketIo);
      if(socketIo){
        return dispatch(socketSuccess(socketIo));
      }else{
        return dispatch(socketErr('Socket not Connected'));
      }
    } catch (err) { 
      alert('12121212121212121', err);
      // dispatch(sendMessageErr(err));
    }
  };
};


// const createMessageRoom = async data => {
  
//     try {
//       const send ={
//         type: data.type,
//         timestamp: Date.now(),
//         uid: data.userid
//       }
//       // db.ref(`messages`).child(data.room).remove();
//       console.log('data.room', data.room);
//       // await db.ref(`messages`).child(data.room).child('members').once("child_added", async (snapshot) => {
//       //   console.log('snapshot.exists()', snapshot.exists());
//       //   if(snapshot.exists()){
//       //     let members = [];
//       //     await snapshot.forEach(async (snap) => {
//       //       console.log('snap.val()', snap.val());
//       //       members.push(snap.val().uid);
//       //       // console.log('userid', snap.val().uid, data.userid);
//       //     });
//       //     console.log(members);
//       //     if(!members.includes(data.userid)){
//       //       await db.ref(`messages`).child(`${data.room}`).child(`members`).push(send);
//       //       return send;
//       //     }
//       //   }else{
//       //     await db.ref(`messages`).child(`${data.room}`).child(`members`).push(send);
//       //     return send;
//       //   }

       
//       //   //  
//       //   //   // if(await chats.includes(data.userid)){

//       //   //   // }else{
//       //   //   //   await db.ref(`messages`).child(`${data.room}`).child(`members`).push(send);
//       //   //   //   return dispatch(sendMessageSuccess(send));
//       //   //   // }
//       //   // }else{
//       //   //   db.ref(`messages`).child(`${data.room}`).child(`members`).push(send);
//       //   //   return dispatch(sendMessageSuccess(send));
//       //   // }
        
//       // });


      
//       // await db.ref(`messages`).child(`${data.room}`).child(`members`).push(send);
//       // return dispatch(sendMessageSuccess(send));
//       // const chats = await [];
//       // await db.ref(`messages`).child(data.room).child('members').once("child_added", async (snap) => {
//       //     var userid = snap.child("uid").val();
//       //     chats.push(snap.val().uid);
//       //   //   console.log('snapshot', snapshot);
//       //   //   await snapshot.forEach(async (snap) => {
//       //   //     console.log('snap.val()', snap.val());
//       //   //     chats.push(snap.val().uid);
//       //   //     // console.log('userid', snap.val().uid, data.userid);
//       //   //   });
//       //   //  
//       //   //   // if(await chats.includes(data.userid)){

//       //   //   // }else{
//       //   //   //   await db.ref(`messages`).child(`${data.room}`).child(`members`).push(send);
//       //   //   //   return dispatch(sendMessageSuccess(send));
//       //   //   // }
//       //   // }else{
//       //   //   db.ref(`messages`).child(`${data.room}`).child(`members`).push(send);
//       //   //   return dispatch(sendMessageSuccess(send));
//       //   // }
        
//       // });
//       // console.log('chats', chats);
     
//       // let response = await DataService.post(`${'notification'}`, data);
//       // if(response.data.success){
//       //   {response.data.message?
//       //     message.success({ content:  response.data.message, duration: 2 })
//       //   :message.success({ content:  'Record Added Successfully', duration: 2 })}
//       //   return dispatch(sendmessageSuccess(response.data.data));
//       // }else{
//       //   {response.data.message?
//       //     message.error({ content:  response.data.message, duration: 2 })
//       //   :message.success({ content:  'Something went wrong!', duration: 2 })}
//       //   return dispatch(sendMessageErr(response.data.message?response.data.message:'Something went wrong!'));
//       // }
      
//     } catch (err) { 
//       // dispatch(sendMessageErr(err));
//     }
// };

// const sendMessageServie = data => {
//   return async dispatch => {
//     try {
//       dispatch(sendMessageBegin());
//       const send ={
//         content: data.content,
//         type: data.type,
//         timestamp: Date.now(),
//         uid: data.userid
//       }
//       console.log(send);
//       // await db.ref(`${data.room}`).push(send);
//       await db.ref(`messages`).child(`${data.room}`).child(`chats`).push(send);
//       return dispatch(sendMessageSuccess(send));
//       // let response = await DataService.post(`${'notification'}`, data);
//       // if(response.data.success){
//       //   {response.data.message?
//       //     message.success({ content:  response.data.message, duration: 2 })
//       //   :message.success({ content:  'Record Added Successfully', duration: 2 })}
//       //   return dispatch(sendmessageSuccess(response.data.data));
//       // }else{
//       //   {response.data.message?
//       //     message.error({ content:  response.data.message, duration: 2 })
//       //   :message.success({ content:  'Something went wrong!', duration: 2 })}
//       //   return dispatch(sendMessageErr(response.data.message?response.data.message:'Something went wrong!'));
//       // }
      
//     } catch (err) { dispatch(sendMessageErr(err));
//     }
//   };
// };

// const getMessageServie = data => {
//   return async dispatch => {
//     try {
//       dispatch(messagesBegin());
//       await db.ref(`messages`).child(`${data}`).child(`chats`).on("value", snapshot => {
//         let chats = [];
//         snapshot.forEach((snap) => {
//           chats.push(snap.val());
//         });
//         return dispatch(messagesSuccess(chats));
//       });
     
      
//     } catch (err) { dispatch(messagesErr(err));
//     }
//   };
// };

// const getRoomMembers = async (data) => {
//   let chats = [];
//   await db.ref(`messages`).child(`${data}`).child(`members`).on("value", (snapshot) => {
//     snapshot.forEach((snap) => {
//       console.log('snap.val()', snap.val());
//       chats.push(snap.val());
//     });
//   });      
//   await console.log('chats', chats);
//   return [...chats];
// }

// const getMembersServie = async (data) => {
//   try {
//     await db.ref(`messages`).child(data).child('members').on("value", async (snapshot) => {
//       console.log('snapshot.exists()', snapshot.exists());
//       if(snapshot.exists()){
//         let members = [];
//         await snapshot.forEach(async (snap) => {
//           console.log('snap.val()', snap.val());
//           members.push(snap.val().uid);
//           // console.log('userid', snap.val().uid, data.userid);
//         });
//         console.log(members);
//         return members;
//       }else{
//         return [];
//         // await db.ref(`messages`).child(`${data.room}`).child(`members`).push(send);
//         // return send;
//       }
//     });
//   } catch (err) { 
//     return [];
//     // dispatch(membersErr(err));
//   }
// };

// export { sendMessageServie, createMessageRoom, getMessageServie, getMembersServie };
// createMessageRoom, getMembersServie, 
export { connectSocket };
