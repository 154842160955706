import Cookies from 'js-cookie';
import actions from './actions';

const { LOGIN_BEGIN, LOGIN_SUCCESS, LOGIN_VARIFY, LOGIN_ERR, LOGOUT_BEGIN, LOGOUT_SUCCESS, LOGOUT_ERR, FORGOT_SUCCESS, FORGOT_BEGIN, FORGOT_ERR, SIGNUP_BEGIN, SIGNUP_SUCCESS, SIGNUP_ERR } = actions;
const data = localStorage.getItem('cinemawallaWebUser');
const initState = {
  login: Cookies.get('cinemawallaWebLogedIn') && data,
  data: data?JSON.parse(window.atob(data)):null,
  loading: false,
  error: null,
};

/**
 *
 * @todo impure state mutation/explaination
 */
const AuthReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case LOGIN_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        login:true,
        data:data,
        loading: false,
      };
    case LOGIN_VARIFY:
        return {
          ...state,
          data: data,
          loading: false,
        };
    case LOGIN_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case LOGOUT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        login: false,
        data: data,
        loading: false,
      };
    case LOGOUT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case FORGOT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case FORGOT_SUCCESS:
      return {
        ...state,
        data: data,
        loading: false,
      };
    case FORGOT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case SIGNUP_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        data: data,
        loading: false,
      };
    case SIGNUP_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default AuthReducer;
